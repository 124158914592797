import {SunFilled, SunOutlined} from '@ant-design/icons';
import {useReactiveVar} from '@apollo/client';
import {themeMode} from '@app/utils/theme';

const ThemeSwitch = () => {
  const mode = useReactiveVar(themeMode);

  const toggleTheme = () => {
    if (mode == 'LIGHT') {
      themeMode('DARK');
    } else {
      themeMode('LIGHT');
    }
  };
  return (
    <div className="right" onClick={toggleTheme}>
      <span className="action">
        {mode == 'LIGHT' && <SunOutlined />}
        {mode == 'DARK' && <SunFilled />}
      </span>
    </div>
  );
};

export default ThemeSwitch;
