import {makeVar} from '@apollo/client';
import client from '@app/graphql';
import {Maybe, User, UserRoles, ViewerProfileDocument} from '@gql/graphql';

/* Configs */
// export const themeMotion = makeVar<boolean>(true);
// export const themeAudio = makeVar<boolean>(true);

/* Access Token */
let accessToken: string | null = null;
export const getAccessToken = (): string | null => accessToken;
export const setAccessToken = (token: string | null) => {
  accessToken = token;
};

/* Viewer */
const emptyUser: Maybe<User> = null;
export const currentViewer = makeVar<Maybe<User>>(emptyUser);
export const isIframe = makeVar<boolean>(false);

/* Fetch User */
export const fetchCurrentUser = async (): Promise<User | null> => {
  return await client
    .query({
      query: ViewerProfileDocument,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    })
    .then(({data}) => {
      if (data?.viewer) {
        hydrateStore(data?.viewer as User);
        return data?.viewer as User;
      }
      return null;
    })
    .catch(() => {
      return null;
    });
};

/* User Roles */
export const hasRole = (roles: UserRoles[]): boolean => {
  const viewer = currentViewer();
  return Boolean(viewer?.role && roles.includes(viewer?.role));
};

/* Store */
export const hydrateStore = (user: User): void => {
  currentViewer(user);
};

export const clearStore = () => {
  accessToken = null;
  currentViewer(emptyUser);
};
