import {msg} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {Card, Col, Result, Row} from 'antd';
import {Fragment} from 'react';

const Error403: React.FC = () => {
  const {_} = useLingui();

  return (
    <Fragment>
      <Row justify="center">
        <Col>
          <Card style={{textAlign: 'center'}} bordered={false}>
            <Result
              status="403"
              title={_(msg`No tienes permisos suficientes`)}
              subTitle={_(msg`Comunícate con un administrador si crees que es un error`)}
            />
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export {Error403};
