import {DownOutlined, LogoutOutlined, UserOutlined} from '@ant-design/icons';
import {UserAvatar} from '@app/ui/UserAvatar';
import {getFirstName} from '@app/utils/names';
import {User} from '@gql/graphql';
import {Trans} from '@lingui/macro';
import type {MenuProps} from 'antd';
import {Dropdown} from 'antd';
import {Link} from 'react-router-dom';

const HeaderUser = ({viewer}: {viewer: User}) => {
  const items: MenuProps['items'] = [
    {
      key: 'edit',
      label: (
        <Link to="/account/edit">
          <Trans>Editar perfil</Trans>
        </Link>
      ),
      icon: <UserOutlined />,
    },
    {
      type: 'divider',
    },
    {
      key: 'logout',
      label: (
        <Link to="/logout">
          <Trans>Cerrar sesión</Trans>
        </Link>
      ),
      icon: <LogoutOutlined />,
    },
  ];

  return (
    <div className="right" style={{marginRight: 7}}>
      <Dropdown menu={{items}}>
        <span className="action account">
          <UserAvatar className="avatar" size="default" viewer={viewer} />
          <span className="name">{getFirstName(viewer)}</span>
          <DownOutlined style={{fontSize: '12px', marginLeft: '3px'}} />
        </span>
      </Dropdown>
    </div>
  );
};

export default HeaderUser;
