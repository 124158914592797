import {msg} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {Skeleton} from 'antd';
import {PageContent} from '.';

type PageContentLoadingProps = {
  title?: string;
  fullWidth?: boolean;
  noCard?: boolean;
};

const PageContentLoading: React.FC<PageContentLoadingProps> = props => {
  const {_} = useLingui();

  return (
    <PageContent header={{title: props?.title ? props?.title : _(msg`Cargando`) + '...'}} {...props}>
      <Skeleton paragraph={{rows: 4, width: '100%'}} active />
    </PageContent>
  );
};

export {PageContentLoading};
