import {i18n} from '@lingui/core';

export const defaultLanguage = 'en';
export const availableLanguages = ['en', 'es'];
export const userLocale = navigator.language;

export const getLocale = (lang?: string) => {
  if (!lang) lang = userLocale;

  const locale = availableLanguages.find(locale => {
    if (locale === lang) return true;
    const regex = new RegExp(`${locale}-.*`);
    return regex.test(lang);
  });
  return locale ? locale : defaultLanguage;
};

/**
 * Load messages for requested locale and activate it.
 */
export async function loadLangCatalog(locale?: string) {
  if (!locale) locale = getLocale();
  const {messages} = await import(`@app/locales/${locale}/messages.po`);
  i18n.loadAndActivate({locale, messages});
}
