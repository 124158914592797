/* eslint-disable react-refresh/only-export-components */
import {
  BarChartOutlined,
  ClusterOutlined,
  DollarOutlined,
  InboxOutlined,
  NotificationOutlined,
  PlusSquareOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {RouteErrorBoundary} from '@app/ui/States/RouteErrorBoundary';
import {fontSize} from '@app/utils/theme';
import {t} from '@lingui/macro';
import {ReactNode, lazy} from 'react';
import type {RouteObject} from 'react-router-dom';
import {Link} from 'react-router-dom';
import {App} from './pages/App/App';

const AccountEdit = lazy(() => import('./pages/Account/Edit'));
const CashtagCreateEdit = lazy(() => import('./pages/Cashtag/CreateEdit'));
const CashtagList = lazy(() => import('./pages/Cashtag/List'));
const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard'));
const GameCreateEdit = lazy(() => import('./pages/Game/CreateEdit'));
const GameList = lazy(() => import('./pages/Game/List'));
const Login = lazy(() => import('./pages/Login'));
const Logout = lazy(() => import('./pages/Logout'));
const PageCreateEdit = lazy(() => import('./pages/Page/CreateEdit'));
const PageList = lazy(() => import('./pages/Page/List'));
const PanelCreateEdit = lazy(() => import('./pages/Panel/CreateEdit'));
const PanelList = lazy(() => import('./pages/Panel/List'));
const ReloadCreateEdit = lazy(() => import('./pages/Reload/CreateEdit'));
const ReloadList = lazy(() => import('./pages/Reload/List'));
const TransactionEdit = lazy(() => import('./pages/Transaction/Edit'));
const TransactionList = lazy(() => import('./pages/Transaction/List'));
const CustomerCreateEdit = lazy(() => import('./pages/Customer/CreateEdit'));
const CustomerList = lazy(() => import('./pages/Customer/List'));
const CustomerAccountCreateEdit = lazy(() => import('./pages/CustomerAccount/CreateEdit'));
const CustomerAccountList = lazy(() => import('./pages/CustomerAccount/List'));
const StatsFinance = lazy(() => import('./pages/Stats/Finance'));
const StatsCustomersCount = lazy(() => import('./pages/Stats/CustomersCount'));
const StatsCustomersCountByPage = lazy(() => import('./pages/Stats/CustomersCountByPage'));
const UserTransactionsCount = lazy(() => import('./pages/Stats/UserTransactionsCount'));
const AccountsCountByGame = lazy(() => import('./pages/Stats/AccountsCountByGame'));
const Shift = lazy(() => import('./pages/Stats/Shift'));
const PagesTable = lazy(() => import('./pages/Stats/PagesTable'));
const UserCreateEdit = lazy(() => import('./pages/User/CreateEdit'));
const UserList = lazy(() => import('./pages/User/List'));
const MarketingConfigure = lazy(() => import('./pages/Marketing/Configure'));

interface RouteObjectExtra {
  breadcrumb?: string | ReactNode;
  icon?: React.ReactNode;
}

type RouteMenu = RouteObject & RouteObjectExtra;
//export type MenuItem = Required<MenuProps>['items'][number];
export interface MenuItem extends RouteObjectExtra {
  key?: string;
  children?: MenuItem[];
}
export const RouterHome = (): RouteMenu[] => {
  return [
    {
      path: '/',
      element: <Dashboard />,
      breadcrumb: t`Dashboard`,
      icon: <PlusSquareOutlined />,
    },
    {
      path: '/stats',
      breadcrumb: t`Estadisticas`,
      icon: <BarChartOutlined />,
      children: [
        {
          path: 'finance',
          element: <StatsFinance />,
          breadcrumb: t`Financiero`,
        } as RouteMenu,
        {
          path: 'pages',
          element: <PagesTable />,
          breadcrumb: t`Páginas`,
        } as RouteMenu,
        {
          path: 'shift',
          element: <Shift />,
          breadcrumb: t`Turnos`,
        } as RouteMenu,
        {
          path: 'customers',
          element: <StatsCustomersCount />,
          breadcrumb: t`Clientes por tipo`,
        } as RouteMenu,
        {
          path: 'customersByPage',
          element: <StatsCustomersCountByPage />,
          breadcrumb: t`Clientes por página`,
        } as RouteMenu,
        {
          path: 'accountsByGame',
          element: <AccountsCountByGame />,
          breadcrumb: t`Cuentas por juego`,
        } as RouteMenu,
        {
          path: 'agents',
          element: <UserTransactionsCount />,
          breadcrumb: t`Agentes`,
        } as RouteMenu,
      ],
    },
    {
      path: '/finance',
      breadcrumb: t`Financiero`,
      icon: <DollarOutlined />,
      children: [
        {
          path: 'transaction',
          element: <TransactionList />,
          breadcrumb: t`Transacciones`,
        } as RouteMenu,
        {
          path: 'transaction/:transactionId/editar',
          element: <TransactionEdit />,
        },
        {
          path: 'reload',
          element: <ReloadList />,
          breadcrumb: t`Recargas`,
        } as RouteMenu,
        {
          path: 'reload/crear',
          element: <ReloadCreateEdit />,
        } as RouteMenu,
        {
          path: 'reload/:reloadId/editar',
          element: <ReloadCreateEdit />,
        },
        {
          path: 'cashtag',
          element: <CashtagList />,
          breadcrumb: t`Cashtags`,
        } as RouteMenu,
        {
          path: 'cashtag/crear',
          element: <CashtagCreateEdit />,
        } as RouteMenu,
        {
          path: 'cashtag/:cashtagId/editar',
          element: <CashtagCreateEdit />,
        },
      ],
    },
    {
      path: '/platform',
      breadcrumb: t`Plataforma`,
      icon: <ClusterOutlined />,
      children: [
        {
          path: 'customer',
          element: <CustomerList />,
          breadcrumb: t`Clientes`,
        } as RouteMenu,
        {
          path: 'customer/crear',
          element: <CustomerCreateEdit />,
        } as RouteMenu,
        {
          path: 'customer/:customerId/editar',
          element: <CustomerCreateEdit />,
        },
        {
          path: 'customer/account',
          element: <CustomerAccountList />,
          breadcrumb: t`Cuentas`,
        } as RouteMenu,
        {
          path: 'customer/account/crear',
          element: <CustomerAccountCreateEdit />,
        } as RouteMenu,
        {
          path: 'customer/account/:customerAccountId/editar',
          element: <CustomerAccountCreateEdit />,
        },
        {
          path: 'panel',
          element: <PanelList />,
          breadcrumb: t`Paneles`,
        } as RouteMenu,
        {
          path: 'panel/crear',
          element: <PanelCreateEdit />,
        } as RouteMenu,
        {
          path: 'panel/:panelId/editar',
          element: <PanelCreateEdit />,
        },
        {
          path: 'game',
          element: <GameList />,
          breadcrumb: t`Juegos`,
        } as RouteMenu,
        {
          path: 'game/crear',
          element: <GameCreateEdit />,
        } as RouteMenu,
        {
          path: 'game/:gameId/editar',
          element: <GameCreateEdit />,
        },
      ],
    },
    {
      path: '/channel',
      breadcrumb: t`Canal`,
      icon: <InboxOutlined />,
      children: [
        {
          path: 'page',
          element: <PageList />,
          breadcrumb: t`Páginas`,
        } as RouteMenu,
        {
          path: 'page/crear',
          element: <PageCreateEdit />,
        } as RouteMenu,
        {
          path: 'page/:pageId/editar',
          element: <PageCreateEdit />,
        },
      ],
    },
    {
      path: '/marketing',
      breadcrumb: t`Marketing`,
      icon: <NotificationOutlined />,
      children: [
        {
          path: 'configure',
          element: <MarketingConfigure />,
          breadcrumb: t`Configurar`,
        } as RouteMenu,
      ],
    },
    {
      path: '/user',
      breadcrumb: t`Usuarios`,
      icon: <UserOutlined />,
      children: [
        {
          index: true,
          element: <UserList />,
        },
        {
          path: 'crear',
          element: <UserCreateEdit />,
        } as RouteMenu,
        {
          path: ':userId/edit',
          element: <UserCreateEdit />,
        },
      ],
    },
    {
      path: 'account',
      icon: <UserOutlined size={fontSize} />,
      children: [
        {
          path: 'edit',
          element: <AccountEdit />,
        },
      ],
    },
  ];
};

export const RouterRoot = (): RouteMenu[] => {
  return [
    {path: '/login', element: <Login />, breadcrumb: t`Iniciar sesión`},
    {path: '/logout', element: <Logout />, breadcrumb: t`Cerrar sesión`},
    {
      path: '/',
      element: <App />,
      children: RouterHome(),
      errorElement: <RouteErrorBoundary />,
    },
  ];
};

function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[]): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const createMenuItems = (router: RouteMenu[], parent?: RouteMenu): MenuItem[] => {
  const nonHidden = router.filter(item => item.breadcrumb);

  return nonHidden.map((item, index) => {
    const parentPath = parent ? parent.path + '/' : '';
    const menuIndex = item.path ? parentPath + item.path : parentPath + index.toString();

    let children = undefined;
    if (item.children) children = createMenuItems(item.children, item);
    children = children && children.length > 0 ? children : undefined;

    const label = children ? item.breadcrumb : <Link to={menuIndex}>{item.breadcrumb}</Link>;

    return getItem(label, menuIndex, item.icon, children);
  });
};

export const menuRouter = (): MenuItem[] => createMenuItems(RouterHome());
