import {ApolloError, ServerParseError} from '@apollo/client';
import {Error403} from './Error403';
import {Error500} from './Error500';
import {ErrorNetwork} from './ErrorNetwork';
import {refetchType} from '@app/types';

interface ErrorRenderProps {
  error: ApolloError;
  refetch: refetchType;
}

const ErrorRender: React.FC<ErrorRenderProps> = ({error, refetch}) => {
  if (error.networkError) {
    const info = error.networkError as ServerParseError;
    console.warn('[ErrorRender] networkError', info.statusCode);

    switch (info.statusCode) {
      case 500:
        return <Error500 />;

      default:
        return <ErrorNetwork refetch={refetch} />;
    }
  } else if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    const info = error.graphQLErrors[0];
    console.warn('[ErrorRender] GraphQLError', info.extensions?.code);

    switch (info.extensions?.statusCode) {
      case 403:
        return <Error403 />;

      default:
        break;
    }
  }

  return <Error500 />;
};

export {ErrorRender};
