import Icon from '@ant-design/icons';
import {useQuery, useReactiveVar} from '@apollo/client';
import {MenuItem, menuRouter} from '@app/Router';
import {currentViewer, hasRole, hydrateStore, isIframe} from '@app/services/store';
import {PageContentLoading} from '@app/ui';
import {Logo} from '@app/ui/SVG/Logo';
import {ErrorRender} from '@app/ui/States/ErrorRender';
import {LoadingFullscreen} from '@app/ui/States/LoadingFullscreen';
import {User, ViewerDocument} from '@gql/graphql';
import * as Sentry from '@sentry/react';
import {Layout, Menu, Typography} from 'antd';
import {ItemType} from 'antd/es/menu/hooks/useItems';
import {Suspense, useEffect, useMemo, useState} from 'react';
import {Link, Navigate, Outlet, useLocation} from 'react-router-dom';
import CompactSwitch from './CompactSwitch';
import './Header.css';
import HeaderUser from './HeaderUser';
import ThemeSwitch from './ThemeSwitch';
const {Header, Content, Footer, Sider} = Layout;
const {Text} = Typography;

const App = () => {
  const {pathname} = useLocation();
  const [collapsed, setCollapsed] = useState(true);
  const [breakpoint, setBreakpoint] = useState(false);
  const inIframe = useReactiveVar(isIframe);
  useReactiveVar(currentViewer);
  const viewer = currentViewer();

  const {loading, error, data, refetch} = useQuery(ViewerDocument, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  const menuFiltered = useMemo(() => {
    if (!viewer) return [];
    const isViewer = hasRole(['VIEWER']);
    const isReviewer = hasRole(['REVIEWER']);
    const isAgent = hasRole(['AGENT']);
    const isSupervisor = hasRole(['SUPERVISOR']);
    const isAdmin = hasRole(['ADMIN']);

    const viewerRoutes = ['/'];
    const reviewerRoutes = [
      '/',
      '/stats',
      '/stats/finance',
      '/stats/shift',
      '/stats/customers',
      '/stats/customersByPage',
      '/stats/accountsByGame',
      '/stats/agents',
    ];
    const agentRoutes = ['/', '/platform', '/platform/panel'];
    const supervisorRoutes = [
      '/',
      // '/stats',
      // '/stats/finance',
      // '/stats/shift',
      // '/stats/customers',
      // '/stats/customersByPage',
      // '/stats/accountsByGame',
      // '/stats/agents',
      '/platform',
      '/finance',
      '/platform/customer',
      '/platform/customer/account',
      '/platform/panel',
      '/finance/transaction',
      '/finance/reload',
      '/finance/cashtag',
      '/marketing',
      '/marketing/configure',
      '',
    ];

    const canViewPath = (menu: MenuItem) => {
      if (!menu || !menu.key) return false;
      if (isViewer) {
        return viewerRoutes.includes(menu.key);
      } else if (isReviewer) {
        return reviewerRoutes.includes(menu.key);
      } else if (isAgent) {
        return agentRoutes.includes(menu.key);
      } else if (isSupervisor) {
        return supervisorRoutes.includes(menu.key);
      } else if (isAdmin) {
        return true;
      }
      return false;
    };

    return menuRouter()
      .filter(menu => canViewPath(menu))
      .map(menu => {
        if (menu.children) menu.children = menu.children.filter(child => canViewPath(child));
        return menu;
      });
  }, [viewer]);

  useEffect(() => {
    if (!data && !error) {
      return;
    }
    hydrateStore(data?.viewer as User);
  }, [data, error]);

  const hasNetworkError = Boolean(error?.networkError);
  const loggedIn = Boolean(data?.viewer);

  if (hasNetworkError)
    return (
      <Layout
        style={{
          textAlign: 'center',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <ErrorRender error={error!} refetch={refetch} />
      </Layout>
    );
  if (error) return <Navigate replace to="/login" state={{from: pathname}} />;
  if (loading || !data || !viewer) return <LoadingFullscreen />;
  if (!loggedIn) return <Navigate replace to="/login" state={{from: pathname}} />;

  Sentry.setUser({username: viewer.username!, ip_address: '{{auto}}'});
  const selectedKeys = pathname.charAt(0) === '/' && pathname.length > 1 ? pathname.substring(1) : pathname;
  const openKeys = selectedKeys.split(/(?=\/)/g);

  return (
    <Layout style={{minHeight: '100vh'}} hasSider={!inIframe}>
      {!inIframe && (
        <Sider
          breakpoint="md"
          collapsible
          defaultCollapsed={true}
          collapsedWidth={breakpoint ? '0' : undefined}
          collapsed={collapsed}
          onCollapse={value => setCollapsed(value)}
          onBreakpoint={value => setBreakpoint(value)}
        >
          <div className="logo-container">
            <Link to="/">
              <Icon component={Logo} className="logo logo-menu" />
            </Link>
          </div>
          <Menu
            theme="dark"
            selectedKeys={[selectedKeys]}
            defaultOpenKeys={openKeys}
            mode="inline"
            items={menuFiltered as ItemType[]}
          />
        </Sider>
      )}
      <Layout>
        {!inIframe && (
          <Header className="header" style={{color: 'white'}}>
            <HeaderUser viewer={viewer} />
            {/* <Notifications /> */}
            <ThemeSwitch />
            <CompactSwitch />
          </Header>
        )}
        <Content className="content">
          <div
            style={{
              minHeight: 360,
              paddingTop: 15,
              paddingBottom: 24,
            }}
          >
            <Suspense fallback={<PageContentLoading title="" />}>
              <Outlet />
            </Suspense>
          </div>
        </Content>
        <Footer className="footer">
          <Text type="secondary">v{__APP_VERSION__}</Text>
        </Footer>
      </Layout>
    </Layout>
  );
};

export {App};
