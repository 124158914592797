import {ApiTwoTone} from '@ant-design/icons';
import {refetchType} from '@app/types';
import {Trans, msg} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {Button, Card, Col, Result, Row} from 'antd';
import {Fragment} from 'react';

const ErrorNetwork = ({refetch}: {refetch: refetchType}) => {
  const {_} = useLingui();

  return (
    <Fragment>
      <Row justify="center">
        <Col className="max-content-width">
          <Card style={{textAlign: 'center'}} bordered={false}>
            <Result
              icon={<ApiTwoTone />}
              title={_(msg`Revisa tu conexión a internet`)}
              subTitle={_(msg`Parece que tienes problemas con la red. Revisa tu conexión o intenta más tarde.`)}
              extra={
                !refetch ? undefined : (
                  <Button type="primary" onClick={() => void refetch()}>
                    <Trans>Reintentar</Trans>
                  </Button>
                )
              }
            />
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export {ErrorNetwork};
