import {Trans, msg} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {Button, Card, Col, Result, Row} from 'antd';
import {Fragment} from 'react';
import {useNavigate} from 'react-router-dom';

const Error500 = () => {
  const navigate = useNavigate();
  const {_} = useLingui();

  return (
    <Fragment>
      <Row justify="center">
        <Col className="max-content-width">
          <Card style={{textAlign: 'center'}} bordered={false}>
            <Result
              status="500"
              title={_(msg`Ha ocurrido un problema con la operación`)}
              subTitle={_(msg`Intenta de nuevo. Si el problema persiste contacta con el administrador.`)}
              extra={
                <Button type="primary" onClick={() => navigate(-1)}>
                  <Trans>Volver atrás</Trans>
                </Button>
              }
            />
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export {Error500};
