/* eslint-disable */
import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>};
export type MakeEmpty<T extends {[key: string]: unknown}, K extends keyof T> = {[_ in K]?: never};
export type Incremental<T> = T | {[P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: {input: string; output: string};
  String: {input: string; output: string};
  Boolean: {input: boolean; output: boolean};
  Int: {input: number; output: number};
  Float: {input: number; output: number};
  /**
   * The `Date` scalar type represents a date. The Date appears in a JSON
   * response as an ISO8601 formatted string, without a time component.
   */
  Date: {input: any; output: any};
  /**
   * The `DateTime` scalar type represents a date and time in the UTC
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string, including UTC timezone ("Z"). The parsed date and time string will
   * be converted to UTC if there is an offset.
   */
  DateTime: {input: any; output: any};
  /**
   * The `Json` scalar type represents arbitrary json string data, represented as UTF-8
   * character sequences. The Json type is most often used to represent a free-form
   * human-readable json string.
   */
  Json: {input: any; output: any};
  /** Represents an uploaded file. */
  Upload: {input: any; output: any};
};

export type AuthCode = {
  __typename?: 'AuthCode';
  code: Maybe<Scalars['String']['output']>;
  ttl: Maybe<Scalars['Int']['output']>;
};

export type AuthResult = {
  __typename?: 'AuthResult';
  access: Maybe<Scalars['String']['output']>;
  session: Maybe<Session>;
  user: Maybe<User>;
};

export type AvatarFile = {
  __typename?: 'AvatarFile';
  avatar: Maybe<Scalars['String']['output']>;
  original: Maybe<Scalars['String']['output']>;
  thumb: Maybe<Scalars['String']['output']>;
};

export type Cashtag = Node & {
  __typename?: 'Cashtag';
  cashtag: Maybe<Scalars['String']['output']>;
  createdBy: Maybe<User>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  insertedAt: Maybe<Scalars['DateTime']['output']>;
  name: Maybe<Scalars['String']['output']>;
  status: Maybe<CashtagStatus>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  updatedBy: Maybe<User>;
};

export type CashtagConnection = {
  __typename?: 'CashtagConnection';
  edges: Maybe<Array<Maybe<CashtagEdge>>>;
  pageInfo: PageInfo;
  /** Total number of records */
  total: Maybe<Scalars['Int']['output']>;
};

export type CashtagEdge = {
  __typename?: 'CashtagEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<Cashtag>;
};

/** Filtering options for cashtag */
export type CashtagFilter = {
  /** Matching a cashtag */
  cashtag: InputMaybe<Scalars['String']['input']>;
  /** Matching a status */
  status: InputMaybe<Array<CashtagStatus>>;
};

/** Ordering options for cashtags */
export type CashtagSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: CashtagSortField;
};

/** The fields allowed to be sorted */
export type CashtagSortField = 'CASHTAG' | 'ID' | 'INSERTED_AT';

export type CashtagStatus = 'ACTIVE' | 'INACTIVE';

export type ChannelStatus = 'ACTIVE' | 'INACTIVE';

export type Customer = Node & {
  __typename?: 'Customer';
  accounts: Maybe<CustomerAccountConnection>;
  createdBy: Maybe<User>;
  cwAccountId: Maybe<Scalars['Int']['output']>;
  cwChatId: Maybe<Scalars['Int']['output']>;
  cwContactId: Maybe<Scalars['Int']['output']>;
  cwWaChatId: Maybe<Scalars['Int']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  insertedAt: Maybe<Scalars['DateTime']['output']>;
  latestMarketing: Maybe<Scalars['DateTime']['output']>;
  latestMarketingText: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  page: Maybe<Page>;
  sourceId: Maybe<Scalars['Int']['output']>;
  sourceIdWa: Maybe<Scalars['Int']['output']>;
  status: Maybe<CustomerStatus>;
  transactionInsights: Maybe<Array<Maybe<CustomerTranasctionsInsight>>>;
  transactions: Maybe<TransactionConnection>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  updatedBy: Maybe<User>;
  weekDaysInsights: Maybe<CustomerWeekDaysInsight>;
  weekInsights: Maybe<CustomerWeekInsight>;
};

export type CustomerAccountsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<CustomerAccountFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<CustomerAccountSort>;
};

export type CustomerTransactionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<TransactionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<TransactionSort>;
};

export type CustomerAccount = Node & {
  __typename?: 'CustomerAccount';
  createdBy: Maybe<User>;
  customer: Maybe<Customer>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  insertedAt: Maybe<Scalars['DateTime']['output']>;
  latestTransaction: Maybe<Transaction>;
  panel: Maybe<Panel>;
  password: Maybe<Scalars['String']['output']>;
  status: Maybe<CustomerAccountStatus>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  updatedBy: Maybe<User>;
  username: Maybe<Scalars['String']['output']>;
};

export type CustomerAccountConnection = {
  __typename?: 'CustomerAccountConnection';
  edges: Maybe<Array<Maybe<CustomerAccountEdge>>>;
  pageInfo: PageInfo;
  /** Total number of records */
  total: Maybe<Scalars['Int']['output']>;
};

export type CustomerAccountEdge = {
  __typename?: 'CustomerAccountEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<CustomerAccount>;
};

/** Filtering options for customer accounts */
export type CustomerAccountFilter = {
  /** Matching a status */
  status: InputMaybe<Array<CustomerAccountStatus>>;
  /** Matching the username */
  username: InputMaybe<Scalars['String']['input']>;
};

/** Ordering options for customer accounts */
export type CustomerAccountSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: CustomerAccountSortField;
};

/** The fields allowed to be sorted */
export type CustomerAccountSortField = 'ID' | 'INSERTED_AT' | 'NAME';

export type CustomerAccountStatus = 'ACTIVE' | 'INACTIVE';

export type CustomerConnection = {
  __typename?: 'CustomerConnection';
  edges: Maybe<Array<Maybe<CustomerEdge>>>;
  pageInfo: PageInfo;
  /** Total number of records */
  total: Maybe<Scalars['Int']['output']>;
};

export type CustomerCountInsight = {
  __typename?: 'CustomerCountInsight';
  group: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  value: Maybe<Scalars['Int']['output']>;
};

/** Filtering options for customer count insights */
export type CustomerCountInsightFilter = {
  /** Starting date */
  groupBy: InputMaybe<InsightGroup>;
  /** Matching in page */
  page: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Starting date */
  since: InputMaybe<Scalars['DateTime']['input']>;
  /** Ending date */
  until: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomerEdge = {
  __typename?: 'CustomerEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<Customer>;
};

/** Filtering options for customer */
export type CustomerFilter = {
  /** Matching the name */
  name: InputMaybe<Scalars['String']['input']>;
  /** Matching a status */
  status: InputMaybe<Array<CustomerStatus>>;
};

/** Ordering options for customers */
export type CustomerSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: CustomerSortField;
};

/** The fields allowed to be sorted */
export type CustomerSortField = 'ID' | 'INSERTED_AT' | 'NAME';

export type CustomerStatus = 'ACTIVE' | 'BLOCKED' | 'INACTIVE';

export type CustomerTranasctionsInsight = {
  __typename?: 'CustomerTranasctionsInsight';
  amount: Maybe<Scalars['Float']['output']>;
  bonus: Maybe<Scalars['Float']['output']>;
  count: Maybe<Scalars['Int']['output']>;
  score: Maybe<Scalars['Float']['output']>;
  tip: Maybe<Scalars['Float']['output']>;
  type: Maybe<TransactionType>;
};

export type CustomerWeekData = {
  __typename?: 'CustomerWeekData';
  amount: Maybe<Scalars['Float']['output']>;
  bonus: Maybe<Scalars['Float']['output']>;
  count: Maybe<Scalars['Int']['output']>;
  score: Maybe<Scalars['Float']['output']>;
};

export type CustomerWeekDaysInsight = {
  __typename?: 'CustomerWeekDaysInsight';
  currentWeek: Maybe<Scalars['Int']['output']>;
  lastWeek: Maybe<Scalars['Int']['output']>;
};

export type CustomerWeekInsight = {
  __typename?: 'CustomerWeekInsight';
  currentWeek: Maybe<CustomerWeekData>;
  lastWeek: Maybe<CustomerWeekData>;
};

export type File = Node & {
  __typename?: 'File';
  contentType: Maybe<Scalars['String']['output']>;
  createdBy: Maybe<User>;
  filename: Maybe<Scalars['String']['output']>;
  hash: Maybe<Scalars['String']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  size: Maybe<Scalars['Int']['output']>;
  type: Maybe<FileType>;
  urls: Maybe<FileUrl>;
};

export type FileType = 'AVATAR' | 'PHOTO';

export type FileUrl = AvatarFile | PhotoFile;

export type FinanceInsight = {
  __typename?: 'FinanceInsight';
  group: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  value: Maybe<Scalars['Float']['output']>;
};

/** Filtering options for finance insights */
export type FinanceInsightFilter = {
  /** Matching in game */
  game: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Starting date */
  groupBy: InputMaybe<InsightGroup>;
  /** Matching in page */
  page: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Starting date */
  since: InputMaybe<Scalars['DateTime']['input']>;
  /** Ending date */
  until: InputMaybe<Scalars['DateTime']['input']>;
};

export type Game = Node & {
  __typename?: 'Game';
  decimal: Maybe<Scalars['Boolean']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  insertedAt: Maybe<Scalars['DateTime']['output']>;
  key: Maybe<Scalars['String']['output']>;
  linkPanel: Maybe<Scalars['String']['output']>;
  linkPlay: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  status: Maybe<GameStatus>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type GameConnection = {
  __typename?: 'GameConnection';
  edges: Maybe<Array<Maybe<GameEdge>>>;
  pageInfo: PageInfo;
  /** Total number of records */
  total: Maybe<Scalars['Int']['output']>;
};

export type GameEdge = {
  __typename?: 'GameEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<Game>;
};

/** Filtering options for game */
export type GameFilter = {
  /** Matching the key */
  key: InputMaybe<Scalars['String']['input']>;
  /** Matching the name */
  name: InputMaybe<Scalars['String']['input']>;
  /** Matching a status */
  status: InputMaybe<Array<GameStatus>>;
};

/** Ordering options for games */
export type GameSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: GameSortField;
};

/** The fields allowed to be sorted */
export type GameSortField = 'ID' | 'INSERTED_AT' | 'NAME';

export type GameStatus = 'ACTIVE' | 'INACTIVE';

export type InsightGroup = 'DAY' | 'HOUR' | 'MONTH' | 'WEEK';

export type LoginMethod = 'CODE' | 'PASSWORD';

export type Node = {
  /** The ID of the object. */
  id: Scalars['ID']['output'];
};

export type Notification = Node & {
  __typename?: 'Notification';
  body: Maybe<Scalars['String']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  insertedAt: Maybe<Scalars['DateTime']['output']>;
  read: Maybe<Scalars['Boolean']['output']>;
  resourceExtra: Maybe<Scalars['String']['output']>;
  resourceId: Maybe<Scalars['String']['output']>;
  title: Maybe<Scalars['String']['output']>;
  type: Maybe<NotificationType>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  user: Maybe<User>;
};

export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  edges: Maybe<Array<Maybe<NotificationEdge>>>;
  pageInfo: PageInfo;
  /** Total number of unread notifications */
  unreadCount: Maybe<Scalars['Int']['output']>;
};

export type NotificationEdge = {
  __typename?: 'NotificationEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<Notification>;
};

/** Filtering options for notifications */
export type NotificationFilter = {
  /** After a date */
  after: InputMaybe<Scalars['DateTime']['input']>;
  /** After a date or if is unread */
  afterOrUnread: InputMaybe<Scalars['DateTime']['input']>;
  read: InputMaybe<Scalars['Boolean']['input']>;
  type: InputMaybe<Scalars['String']['input']>;
};

/** Ordering options for notifications */
export type NotificationSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: NotificationSortField;
};

/** The fields allowed to be sorted */
export type NotificationSortField = 'ID' | 'INSERTED_AT';

export type NotificationType = 'LOSS_TOP_SCORE' | 'NEW_BEST_SCORE' | 'NEW_TOP_SCORE';

export type OrderDirection = 'ASC' | 'DESC';

/** The fields allowed to be sorted */
export type OrderUserField = 'ID' | 'INSERTED_AT' | 'NAME' | 'UPDATED_AT' | 'USERNAME';

export type Page = Node & {
  __typename?: 'Page';
  createdBy: Maybe<User>;
  cwId: Maybe<Scalars['Int']['output']>;
  cwWaInboxId: Maybe<Scalars['Int']['output']>;
  fbId: Maybe<Scalars['Int']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  insertedAt: Maybe<Scalars['DateTime']['output']>;
  marketing: Maybe<Scalars['Json']['output']>;
  name: Maybe<Scalars['String']['output']>;
  status: Maybe<ChannelStatus>;
  token: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  updatedBy: Maybe<User>;
  waId: Maybe<Scalars['Int']['output']>;
  waToken: Maybe<Scalars['String']['output']>;
};

export type PageConnection = {
  __typename?: 'PageConnection';
  edges: Maybe<Array<Maybe<PageEdge>>>;
  pageInfo: PageInfo;
  /** Total number of records */
  total: Maybe<Scalars['Int']['output']>;
};

export type PageEdge = {
  __typename?: 'PageEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<Page>;
};

/** Filtering options for page */
export type PageFilter = {
  /** Matching the cw_id */
  cwId: InputMaybe<Scalars['String']['input']>;
  /** Matching the fb_id */
  fbId: InputMaybe<Scalars['String']['input']>;
  /** Matching the name */
  name: InputMaybe<Scalars['String']['input']>;
  /** Matching a status */
  status: InputMaybe<Array<ChannelStatus>>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']['output']>;
};

/** Ordering options for pages */
export type PageSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: PageSortField;
};

/** The fields allowed to be sorted */
export type PageSortField = 'ID' | 'INSERTED_AT' | 'NAME';

export type PagesTransactionInsightData = {
  __typename?: 'PagesTransactionInsightData';
  amount: Maybe<Scalars['Float']['output']>;
  bonus: Maybe<Scalars['Float']['output']>;
  count: Maybe<Scalars['Int']['output']>;
  pageName: Maybe<Scalars['String']['output']>;
  score: Maybe<Scalars['Float']['output']>;
  tip: Maybe<Scalars['Float']['output']>;
  type: Maybe<TransactionType>;
};

/** Filtering options for pages insights */
export type PagesTransactionInsightFilter = {
  /** Starting date */
  since: InputMaybe<Scalars['DateTime']['input']>;
  /** Ending date */
  until: InputMaybe<Scalars['DateTime']['input']>;
};

export type Panel = Node & {
  __typename?: 'Panel';
  createdBy: Maybe<User>;
  game: Maybe<Game>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  insertedAt: Maybe<Scalars['DateTime']['output']>;
  name: Maybe<Scalars['String']['output']>;
  password: Maybe<Scalars['String']['output']>;
  score: Maybe<Scalars['Float']['output']>;
  status: Maybe<PanelStatus>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  updatedBy: Maybe<User>;
  username: Maybe<Scalars['String']['output']>;
};

export type PanelConnection = {
  __typename?: 'PanelConnection';
  edges: Maybe<Array<Maybe<PanelEdge>>>;
  pageInfo: PageInfo;
  /** Total number of records */
  total: Maybe<Scalars['Int']['output']>;
};

export type PanelEdge = {
  __typename?: 'PanelEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<Panel>;
};

/** Filtering options for panel */
export type PanelFilter = {
  /** Matching games */
  games: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Matching the name */
  name: InputMaybe<Scalars['String']['input']>;
  /** Matching a status */
  status: InputMaybe<Array<PanelStatus>>;
  /** Matching the username */
  username: InputMaybe<Scalars['String']['input']>;
};

/** Ordering options for panels */
export type PanelSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: PanelSortField;
};

/** The fields allowed to be sorted */
export type PanelSortField = 'ID' | 'INSERTED_AT' | 'NAME' | 'SCORE';

export type PanelStatus = 'ACTIVE' | 'INACTIVE';

export type PhotoFile = {
  __typename?: 'PhotoFile';
  original: Maybe<Scalars['String']['output']>;
};

export type Reload = Node & {
  __typename?: 'Reload';
  cost: Maybe<Scalars['Float']['output']>;
  createdBy: Maybe<User>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  insertedAt: Maybe<Scalars['DateTime']['output']>;
  newScore: Maybe<Scalars['Float']['output']>;
  note: Maybe<Scalars['String']['output']>;
  oldScore: Maybe<Scalars['Float']['output']>;
  panel: Maybe<Panel>;
  score: Maybe<Scalars['Float']['output']>;
  totalCost: Maybe<Scalars['Float']['output']>;
  type: Maybe<ReloadType>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  updatedBy: Maybe<User>;
};

export type ReloadConnection = {
  __typename?: 'ReloadConnection';
  edges: Maybe<Array<Maybe<ReloadEdge>>>;
  pageInfo: PageInfo;
  /** Total number of records */
  total: Maybe<Scalars['Int']['output']>;
};

export type ReloadEdge = {
  __typename?: 'ReloadEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<Reload>;
};

/** Filtering options for game id */
export type ReloadFilter = {
  /** Matching a status */
  type: InputMaybe<Array<ReloadType>>;
};

/** Ordering options for game ids */
export type ReloadSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: ReloadSortField;
};

/** The fields allowed to be sorted */
export type ReloadSortField = 'ID' | 'INSERTED_AT' | 'NAME';

export type ReloadType = 'OTHER' | 'REDEEM' | 'RELOAD';

export type RootMutationType = {
  __typename?: 'RootMutationType';
  /** Auto create customer */
  autoCreateCustomer: Maybe<SuccessResult>;
  /** Auto update customer labels */
  autoUpdateCustomerLabels: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Create a new cashtag */
  createCashtag: Maybe<Cashtag>;
  /** Create a new customer */
  createCustomer: Maybe<Customer>;
  /** Create a new customer account */
  createCustomerAccount: Maybe<CustomerAccount>;
  /** Create a new game */
  createGame: Maybe<Game>;
  /** Create a new page */
  createPage: Maybe<Page>;
  /** Create a new panel */
  createPanel: Maybe<Panel>;
  /** Create a new reload */
  createReload: Maybe<Reload>;
  /** Create a new transaction */
  createTransaction: Maybe<Transaction>;
  /** Register a new user */
  createUser: Maybe<User>;
  /** Delete a cashtag */
  deleteCashtag: Maybe<SuccessResult>;
  /** Delete a customer */
  deleteCustomer: Maybe<SuccessResult>;
  /** Delete a Game ID */
  deleteCustomerAccount: Maybe<SuccessResult>;
  /** Delete a game */
  deleteGame: Maybe<SuccessResult>;
  /** Delete a notifications */
  deleteNotification: Maybe<SuccessResult>;
  /** Delete a page */
  deletePage: Maybe<SuccessResult>;
  /** Delete a panel */
  deletePanel: Maybe<SuccessResult>;
  /** Delete a reload */
  deleteReload: Maybe<SuccessResult>;
  /** Delete a transaction */
  deleteTransaction: Maybe<SuccessResult>;
  /** Delete a user account */
  deleteUserAccount: Maybe<SuccessResult>;
  /** Creates an auth code */
  generateAuthCode: Maybe<AuthCode>;
  /** Mark as read all unread notifications */
  readAllNotifications: Maybe<SuccessResult>;
  /** Mark as read one notifications */
  readNotification: Maybe<SuccessResult>;
  /** Exchange the refresh token for an access token */
  refreshToken: Maybe<AuthResult>;
  /** Reload panel scores */
  reloadPanelScore: Maybe<SuccessResult>;
  /** Request the auth code */
  requestAuthCode: Maybe<SuccessResult>;
  /** Retrive the token for auth */
  signIn: Maybe<AuthResult>;
  /** Terminates current user session */
  signOut: Maybe<SuccessResult>;
  /** Terminates a user session */
  terminateSession: Maybe<SuccessResult>;
  /** Update a cashtag */
  updateCashtag: Maybe<Cashtag>;
  /** Update a customer */
  updateCustomer: Maybe<Customer>;
  /** Update a Game ID */
  updateCustomerAccount: Maybe<CustomerAccount>;
  /** Update a game */
  updateGame: Maybe<Game>;
  /** Update a page */
  updatePage: Maybe<Page>;
  /** Update a panel */
  updatePanel: Maybe<Panel>;
  /** Update the user profile data */
  updateProfile: Maybe<User>;
  /** Update a reload */
  updateReload: Maybe<Reload>;
  /** Update the user's session info */
  updateSession: Maybe<Session>;
  /** Update a transaction */
  updateTransaction: Maybe<Transaction>;
  /** Update given user */
  updateUser: Maybe<User>;
  /** Upload a new file */
  uploadFile: Maybe<File>;
};

export type RootMutationTypeAutoCreateCustomerArgs = {
  token: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type RootMutationTypeAutoUpdateCustomerLabelsArgs = {
  token: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type RootMutationTypeCreateCashtagArgs = {
  cashtag: Scalars['String']['input'];
  name: Scalars['String']['input'];
  status: CashtagStatus;
};

export type RootMutationTypeCreateCustomerArgs = {
  cwAccountId: Scalars['String']['input'];
  cwChatId: Scalars['String']['input'];
  cwContactId: Scalars['String']['input'];
  cwWaChatId: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  pageId: Scalars['ID']['input'];
  sourceId: InputMaybe<Scalars['Int']['input']>;
  sourceIdWa: InputMaybe<Scalars['Int']['input']>;
  status: InputMaybe<CustomerStatus>;
};

export type RootMutationTypeCreateCustomerAccountArgs = {
  customerId: Scalars['ID']['input'];
  panelId: Scalars['ID']['input'];
  password: Scalars['String']['input'];
  status: CustomerAccountStatus;
  username: Scalars['String']['input'];
};

export type RootMutationTypeCreateGameArgs = {
  decimal: InputMaybe<Scalars['Boolean']['input']>;
  key: Scalars['String']['input'];
  linkPanel: InputMaybe<Scalars['String']['input']>;
  linkPlay: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  status: GameStatus;
};

export type RootMutationTypeCreatePageArgs = {
  cwId: InputMaybe<Scalars['Int']['input']>;
  cwWaInboxId: InputMaybe<Scalars['Int']['input']>;
  fbId: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  status: ChannelStatus;
  token: InputMaybe<Scalars['String']['input']>;
  waId: InputMaybe<Scalars['Int']['input']>;
  waToken: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeCreatePanelArgs = {
  gameId: Scalars['ID']['input'];
  name: InputMaybe<Scalars['String']['input']>;
  password: InputMaybe<Scalars['String']['input']>;
  score: InputMaybe<Scalars['Float']['input']>;
  status: PanelStatus;
  username: Scalars['String']['input'];
};

export type RootMutationTypeCreateReloadArgs = {
  cost: InputMaybe<Scalars['Float']['input']>;
  note: InputMaybe<Scalars['String']['input']>;
  panelId: Scalars['ID']['input'];
  score: Scalars['Float']['input'];
  type: ReloadType;
};

export type RootMutationTypeCreateTransactionArgs = {
  amount: InputMaybe<Scalars['Float']['input']>;
  bonus: InputMaybe<Scalars['Float']['input']>;
  customerAccountId: Scalars['ID']['input'];
  customerCashtag: InputMaybe<Scalars['String']['input']>;
  customerId: Scalars['ID']['input'];
  files: InputMaybe<Array<Scalars['ID']['input']>>;
  freeplay: InputMaybe<Scalars['Boolean']['input']>;
  note: InputMaybe<Scalars['String']['input']>;
  ourCashtagId: InputMaybe<Scalars['ID']['input']>;
  score: Scalars['Float']['input'];
  shift: InputMaybe<TransactionShift>;
  tip: InputMaybe<Scalars['Float']['input']>;
  type: InputMaybe<TransactionType>;
};

export type RootMutationTypeCreateUserArgs = {
  name: Scalars['String']['input'];
  password: InputMaybe<Scalars['String']['input']>;
  role: InputMaybe<UserRoles>;
  status: InputMaybe<UserStatuses>;
  username: Scalars['String']['input'];
};

export type RootMutationTypeDeleteCashtagArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeDeleteCustomerArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeDeleteCustomerAccountArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeDeleteGameArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeDeleteNotificationArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeDeletePageArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeDeletePanelArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeDeleteReloadArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeDeleteTransactionArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeDeleteUserAccountArgs = {
  userId: Scalars['ID']['input'];
};

export type RootMutationTypeReadNotificationArgs = {
  id: Scalars['ID']['input'];
};

export type RootMutationTypeRequestAuthCodeArgs = {
  username: Scalars['String']['input'];
};

export type RootMutationTypeSignInArgs = {
  code: InputMaybe<Scalars['String']['input']>;
  password: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type RootMutationTypeTerminateSessionArgs = {
  sessionId: Scalars['ID']['input'];
};

export type RootMutationTypeUpdateCashtagArgs = {
  cashtag: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  status: InputMaybe<CashtagStatus>;
};

export type RootMutationTypeUpdateCustomerArgs = {
  botFlow: InputMaybe<Scalars['String']['input']>;
  botMode: InputMaybe<Scalars['String']['input']>;
  botState: InputMaybe<Scalars['String']['input']>;
  cwAccountId: InputMaybe<Scalars['String']['input']>;
  cwChatId: InputMaybe<Scalars['String']['input']>;
  cwContactId: InputMaybe<Scalars['String']['input']>;
  cwWaChatId: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  language: InputMaybe<Scalars['String']['input']>;
  marketing: InputMaybe<Scalars['Boolean']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  pageId: InputMaybe<Scalars['ID']['input']>;
  sourceId: InputMaybe<Scalars['Int']['input']>;
  sourceIdWa: InputMaybe<Scalars['Int']['input']>;
  status: InputMaybe<CustomerStatus>;
};

export type RootMutationTypeUpdateCustomerAccountArgs = {
  customerId: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  panelId: InputMaybe<Scalars['ID']['input']>;
  password: InputMaybe<Scalars['String']['input']>;
  status: InputMaybe<CustomerAccountStatus>;
  username: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeUpdateGameArgs = {
  decimal: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  key: InputMaybe<Scalars['String']['input']>;
  linkPanel: InputMaybe<Scalars['String']['input']>;
  linkPlay: InputMaybe<Scalars['String']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  status: InputMaybe<GameStatus>;
};

export type RootMutationTypeUpdatePageArgs = {
  cwId: InputMaybe<Scalars['Int']['input']>;
  cwWaInboxId: InputMaybe<Scalars['Int']['input']>;
  fbId: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  marketing: InputMaybe<Scalars['Json']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  status: InputMaybe<ChannelStatus>;
  token: InputMaybe<Scalars['String']['input']>;
  waId: InputMaybe<Scalars['Int']['input']>;
  waToken: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeUpdatePanelArgs = {
  id: Scalars['ID']['input'];
  name: InputMaybe<Scalars['String']['input']>;
  password: InputMaybe<Scalars['String']['input']>;
  status: InputMaybe<PanelStatus>;
};

export type RootMutationTypeUpdateProfileArgs = {
  name: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeUpdateReloadArgs = {
  cost: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  note: InputMaybe<Scalars['String']['input']>;
  panelId: InputMaybe<Scalars['ID']['input']>;
  score: InputMaybe<Scalars['Float']['input']>;
  type: InputMaybe<ReloadType>;
};

export type RootMutationTypeUpdateSessionArgs = {
  appVersion: InputMaybe<Scalars['String']['input']>;
  browser: InputMaybe<Scalars['String']['input']>;
  browserVersion: InputMaybe<Scalars['String']['input']>;
  langCode: InputMaybe<Scalars['String']['input']>;
  system: InputMaybe<Scalars['String']['input']>;
  systemVersion: InputMaybe<Scalars['String']['input']>;
  timezone: InputMaybe<Scalars['String']['input']>;
};

export type RootMutationTypeUpdateTransactionArgs = {
  customerCashtag: InputMaybe<Scalars['String']['input']>;
  freeplay: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  insertedAt: InputMaybe<Scalars['DateTime']['input']>;
  note: InputMaybe<Scalars['String']['input']>;
  ourCashtagId: InputMaybe<Scalars['ID']['input']>;
  shift: InputMaybe<TransactionShift>;
};

export type RootMutationTypeUpdateUserArgs = {
  changes: UpdateUserInput;
  id: Scalars['ID']['input'];
};

export type RootMutationTypeUploadFileArgs = {
  file: Scalars['Upload']['input'];
  type: FileType;
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  /** Shows the methods available to login */
  availableLoginMethods: Maybe<Array<Maybe<LoginMethod>>>;
  cashtags: Maybe<CashtagConnection>;
  /** Check if the given username is available for take */
  checkUsernameAvailable: Maybe<SuccessResult>;
  customerAccounts: Maybe<CustomerAccountConnection>;
  /** Insight data of customer */
  customerCountByGameInsights: Maybe<Array<Maybe<CustomerCountInsight>>>;
  /** Insight data of customer */
  customerCountByPageInsights: Maybe<Array<Maybe<CustomerCountInsight>>>;
  /** Insight data of customer */
  customerCountInsights: Maybe<Array<Maybe<CustomerCountInsight>>>;
  customers: Maybe<CustomerConnection>;
  /** Insight data of finance */
  financeInsights: Maybe<Array<Maybe<FinanceInsight>>>;
  /** Check if the file is already uploaded by the same user */
  findFileByHash: Maybe<File>;
  games: Maybe<GameConnection>;
  /** Get a customer form URL or Customer Account ID */
  getCustomer: Maybe<Customer>;
  getCustomerExternal: Maybe<Customer>;
  /** Find a user by auth credentials */
  getUserByCredentials: Maybe<User>;
  node: Maybe<Node>;
  pages: Maybe<PageConnection>;
  /** Insight data of pages */
  pagesTransactionInsight: Maybe<Array<Maybe<PagesTransactionInsightData>>>;
  panels: Maybe<PanelConnection>;
  reloads: Maybe<ReloadConnection>;
  /** Insight data of user */
  shiftTransactionsInsights: Maybe<Array<Maybe<ShiftTransactionsInsight>>>;
  transactions: Maybe<TransactionConnection>;
  /** Insight data of user */
  userTransactionsInsights: Maybe<Array<Maybe<UserTransactionsInsight>>>;
  users: Maybe<UserConnection>;
  /** Returns the current authenticated user. */
  viewer: Maybe<User>;
};

export type RootQueryTypeAvailableLoginMethodsArgs = {
  username: Scalars['String']['input'];
};

export type RootQueryTypeCashtagsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<CashtagFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<CashtagSort>;
};

export type RootQueryTypeCheckUsernameAvailableArgs = {
  username: Scalars['String']['input'];
};

export type RootQueryTypeCustomerAccountsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<CustomerAccountFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<CustomerAccountSort>;
};

export type RootQueryTypeCustomerCountByGameInsightsArgs = {
  filter: InputMaybe<CustomerCountInsightFilter>;
};

export type RootQueryTypeCustomerCountByPageInsightsArgs = {
  filter: InputMaybe<CustomerCountInsightFilter>;
};

export type RootQueryTypeCustomerCountInsightsArgs = {
  filter: InputMaybe<CustomerCountInsightFilter>;
};

export type RootQueryTypeCustomersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<CustomerFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<CustomerSort>;
};

export type RootQueryTypeFinanceInsightsArgs = {
  filter: InputMaybe<FinanceInsightFilter>;
};

export type RootQueryTypeFindFileByHashArgs = {
  hash: Scalars['String']['input'];
  type: FileType;
};

export type RootQueryTypeGamesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<GameFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<GameSort>;
};

export type RootQueryTypeGetCustomerArgs = {
  accountUsername: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['ID']['input']>;
  url: InputMaybe<Scalars['String']['input']>;
};

export type RootQueryTypeGetCustomerExternalArgs = {
  id: InputMaybe<Scalars['ID']['input']>;
  token: Scalars['String']['input'];
};

export type RootQueryTypeGetUserByCredentialsArgs = {
  username: Scalars['String']['input'];
};

export type RootQueryTypeNodeArgs = {
  id: Scalars['ID']['input'];
};

export type RootQueryTypePagesArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<PageFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<PageSort>;
};

export type RootQueryTypePagesTransactionInsightArgs = {
  filter: InputMaybe<PagesTransactionInsightFilter>;
};

export type RootQueryTypePanelsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<PanelFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<PanelSort>;
};

export type RootQueryTypeReloadsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<ReloadFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ReloadSort>;
};

export type RootQueryTypeShiftTransactionsInsightsArgs = {
  filter: InputMaybe<ShiftTransactionsInsightsFilter>;
};

export type RootQueryTypeTransactionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<TransactionFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<TransactionSort>;
};

export type RootQueryTypeUserTransactionsInsightsArgs = {
  filter: InputMaybe<UserTransactionsInsightsFilter>;
};

export type RootQueryTypeUsersArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<UserFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<UserSort>;
};

export type Session = Node & {
  __typename?: 'Session';
  appVersion: Maybe<Scalars['String']['output']>;
  browser: Maybe<Scalars['String']['output']>;
  browserVersion: Maybe<Scalars['String']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  langCode: Maybe<Scalars['String']['output']>;
  remoteIp: Maybe<Scalars['String']['output']>;
  system: Maybe<Scalars['String']['output']>;
  systemVersion: Maybe<Scalars['String']['output']>;
  terminatedAt: Maybe<Scalars['Date']['output']>;
  timezone: Maybe<Scalars['String']['output']>;
  user: Maybe<User>;
};

export type SessionConnection = {
  __typename?: 'SessionConnection';
  edges: Maybe<Array<Maybe<SessionEdge>>>;
  pageInfo: PageInfo;
};

export type SessionEdge = {
  __typename?: 'SessionEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<Session>;
};

export type ShiftTransactionsInsight = {
  __typename?: 'ShiftTransactionsInsight';
  group: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  value: Maybe<Scalars['Float']['output']>;
};

/** Filtering options for shift insights */
export type ShiftTransactionsInsightsFilter = {
  /** Group date */
  groupBy: InputMaybe<InsightGroup>;
  /** Starting date */
  since: InputMaybe<Scalars['DateTime']['input']>;
  /** Transaction type */
  type: InputMaybe<TransactionType>;
  /** Ending date */
  until: InputMaybe<Scalars['DateTime']['input']>;
};

/** Success result of the mutation */
export type SuccessResult = {
  __typename?: 'SuccessResult';
  success: Maybe<Scalars['Boolean']['output']>;
};

export type Transaction = Node & {
  __typename?: 'Transaction';
  amount: Maybe<Scalars['Float']['output']>;
  bonus: Maybe<Scalars['Float']['output']>;
  createdBy: Maybe<User>;
  customer: Maybe<Customer>;
  customerAccount: Maybe<CustomerAccount>;
  customerCashtag: Maybe<Scalars['String']['output']>;
  files: Maybe<Array<Maybe<File>>>;
  freeplay: Maybe<Scalars['Boolean']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  insertedAt: Maybe<Scalars['DateTime']['output']>;
  note: Maybe<Scalars['String']['output']>;
  ourCashtag: Maybe<Cashtag>;
  score: Maybe<Scalars['Float']['output']>;
  shift: Maybe<TransactionShift>;
  tip: Maybe<Scalars['Float']['output']>;
  type: Maybe<TransactionType>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  updatedBy: Maybe<User>;
};

export type TransactionConnection = {
  __typename?: 'TransactionConnection';
  edges: Maybe<Array<Maybe<TransactionEdge>>>;
  pageInfo: PageInfo;
  /** Total number of records */
  total: Maybe<Scalars['Int']['output']>;
};

export type TransactionEdge = {
  __typename?: 'TransactionEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<Transaction>;
};

/** Filtering options for transaction */
export type TransactionFilter = {
  /** Matching in auhor */
  createdBy: InputMaybe<Scalars['ID']['input']>;
  /** Matching a customer account username */
  customerAccount: InputMaybe<Scalars['String']['input']>;
  /** Matching a customer cashtag */
  customerCashtag: InputMaybe<Scalars['String']['input']>;
  /** Matching by ID */
  id: InputMaybe<Scalars['ID']['input']>;
  /** Matching a page */
  page: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Matching a panel */
  panel: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Matching a shift */
  shift: InputMaybe<Array<TransactionShift>>;
  /** Starting date */
  since: InputMaybe<Scalars['DateTime']['input']>;
  /** Matching a type */
  type: InputMaybe<Array<TransactionType>>;
  /** Ending date */
  until: InputMaybe<Scalars['DateTime']['input']>;
};

export type TransactionShift = 'EVENING' | 'MORNING' | 'NIGHT';

/** Ordering options for transactions */
export type TransactionSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: TransactionSortField;
};

/** The fields allowed to be sorted */
export type TransactionSortField = 'ID' | 'INSERTED_AT';

export type TransactionType = 'CASHOUT' | 'DEPOSIT' | 'FREEPLAY' | 'OTHER' | 'TRANSFER';

/** Input object for update the user data */
export type UpdateUserInput = {
  name: InputMaybe<Scalars['String']['input']>;
  password: InputMaybe<Scalars['String']['input']>;
  role: InputMaybe<UserRoles>;
  status: InputMaybe<UserStatuses>;
  username: InputMaybe<Scalars['String']['input']>;
};

export type User = Node & {
  __typename?: 'User';
  hasPassword: Maybe<Scalars['Boolean']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  insertedAt: Maybe<Scalars['DateTime']['output']>;
  name: Maybe<Scalars['String']['output']>;
  notifications: Maybe<NotificationConnection>;
  role: Maybe<UserRoles>;
  sessions: Maybe<SessionConnection>;
  status: Maybe<UserStatuses>;
  username: Maybe<Scalars['String']['output']>;
};

export type UserNotificationsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<NotificationFilter>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  order: InputMaybe<NotificationSort>;
};

export type UserSessionsArgs = {
  after: InputMaybe<Scalars['String']['input']>;
  before: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  last: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<OrderDirection>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edges: Maybe<Array<Maybe<UserEdge>>>;
  pageInfo: PageInfo;
  /** Total number of records */
  total: Maybe<Scalars['Int']['output']>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor: Maybe<Scalars['String']['output']>;
  node: Maybe<User>;
};

/** Filtering options for users */
export type UserFilter = {
  /** Matching name */
  name: InputMaybe<Scalars['String']['input']>;
  /** Matching a role */
  role: InputMaybe<Array<UserRoles>>;
  /** Matching a status */
  status: InputMaybe<Array<UserStatuses>>;
  /** Matching username */
  username: InputMaybe<Scalars['String']['input']>;
};

export type UserRoles = 'ADMIN' | 'AGENT' | 'REVIEWER' | 'SUPERVISOR' | 'VIEWER';

/** Ordering options for users */
export type UserSort = {
  /** Order direction */
  direction: OrderDirection;
  /** The field to order */
  field: OrderUserField;
};

export type UserStatuses = 'ACTIVE' | 'DELETED' | 'SUSPENDED';

export type UserTransactionsInsight = {
  __typename?: 'UserTransactionsInsight';
  group: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  value: Maybe<Scalars['Int']['output']>;
};

/** Filtering options for user count insights */
export type UserTransactionsInsightsFilter = {
  /** Group date */
  groupBy: InputMaybe<InsightGroup>;
  /** Starting date */
  since: InputMaybe<Scalars['DateTime']['input']>;
  /** Transaction type */
  type: InputMaybe<Array<InputMaybe<TransactionType>>>;
  /** Ending date */
  until: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreateCashtagMutationVariables = Exact<{
  cashtag: Scalars['String']['input'];
  name: Scalars['String']['input'];
  status: CashtagStatus;
}>;

export type CreateCashtagMutation = {
  __typename?: 'RootMutationType';
  createCashtag: {__typename?: 'Cashtag'; id: string} | null;
};

export type CreateCustomerMutationVariables = Exact<{
  cwAccountId: Scalars['String']['input'];
  cwChatId: Scalars['String']['input'];
  cwContactId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  pageId: Scalars['ID']['input'];
  status: InputMaybe<CustomerStatus>;
}>;

export type CreateCustomerMutation = {
  __typename?: 'RootMutationType';
  createCustomer: {__typename?: 'Customer'; id: string} | null;
};

export type CreateCustomerAccountMutationVariables = Exact<{
  customerId: Scalars['ID']['input'];
  panelId: Scalars['ID']['input'];
  password: Scalars['String']['input'];
  status: CustomerAccountStatus;
  username: Scalars['String']['input'];
}>;

export type CreateCustomerAccountMutation = {
  __typename?: 'RootMutationType';
  createCustomerAccount: {__typename?: 'CustomerAccount'; id: string} | null;
};

export type CreateGameMutationVariables = Exact<{
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
  status: GameStatus;
  decimal: InputMaybe<Scalars['Boolean']['input']>;
  linkPanel: InputMaybe<Scalars['String']['input']>;
  linkPlay: InputMaybe<Scalars['String']['input']>;
}>;

export type CreateGameMutation = {
  __typename?: 'RootMutationType';
  createGame: {__typename?: 'Game'; id: string} | null;
};

export type CreatePageMutationVariables = Exact<{
  name: Scalars['String']['input'];
  status: ChannelStatus;
  cwId: InputMaybe<Scalars['Int']['input']>;
  fbId: InputMaybe<Scalars['Int']['input']>;
}>;

export type CreatePageMutation = {
  __typename?: 'RootMutationType';
  createPage: {__typename?: 'Page'; id: string} | null;
};

export type CreatePanelMutationVariables = Exact<{
  gameId: Scalars['ID']['input'];
  status: PanelStatus;
  username: Scalars['String']['input'];
  password: InputMaybe<Scalars['String']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
}>;

export type CreatePanelMutation = {
  __typename?: 'RootMutationType';
  createPanel: {__typename?: 'Panel'; id: string} | null;
};

export type CreateReloadMutationVariables = Exact<{
  cost: InputMaybe<Scalars['Float']['input']>;
  panelId: Scalars['ID']['input'];
  score: Scalars['Float']['input'];
  type: ReloadType;
}>;

export type CreateReloadMutation = {
  __typename?: 'RootMutationType';
  createReload: {__typename?: 'Reload'; id: string} | null;
};

export type CreateTransactionMutationVariables = Exact<{
  customerAccountId: Scalars['ID']['input'];
  customerId: Scalars['ID']['input'];
  amount: InputMaybe<Scalars['Float']['input']>;
  bonus: InputMaybe<Scalars['Float']['input']>;
  customerCashtag: InputMaybe<Scalars['String']['input']>;
  freeplay: InputMaybe<Scalars['Boolean']['input']>;
  note: InputMaybe<Scalars['String']['input']>;
  ourCashtagId: InputMaybe<Scalars['ID']['input']>;
  score: Scalars['Float']['input'];
  tip: InputMaybe<Scalars['Float']['input']>;
  type: InputMaybe<TransactionType>;
  shift: InputMaybe<TransactionShift>;
  files: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;

export type CreateTransactionMutation = {
  __typename?: 'RootMutationType';
  createTransaction: {__typename?: 'Transaction'; id: string} | null;
};

export type CreateUserMutationVariables = Exact<{
  name: Scalars['String']['input'];
  username: Scalars['String']['input'];
  password: InputMaybe<Scalars['String']['input']>;
  role: InputMaybe<UserRoles>;
  status: InputMaybe<UserStatuses>;
}>;

export type CreateUserMutation = {
  __typename?: 'RootMutationType';
  createUser: {__typename?: 'User'; id: string} | null;
};

export type DeleteCashtagMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteCashtagMutation = {
  __typename?: 'RootMutationType';
  deleteCashtag: {__typename?: 'SuccessResult'; success: boolean | null} | null;
};

export type DeleteCustomerMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteCustomerMutation = {
  __typename?: 'RootMutationType';
  deleteCustomer: {__typename?: 'SuccessResult'; success: boolean | null} | null;
};

export type DeleteCustomerAccountMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteCustomerAccountMutation = {
  __typename?: 'RootMutationType';
  deleteCustomerAccount: {__typename?: 'SuccessResult'; success: boolean | null} | null;
};

export type DeleteGameMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteGameMutation = {
  __typename?: 'RootMutationType';
  deleteGame: {__typename?: 'SuccessResult'; success: boolean | null} | null;
};

export type DeletePageMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeletePageMutation = {
  __typename?: 'RootMutationType';
  deletePage: {__typename?: 'SuccessResult'; success: boolean | null} | null;
};

export type DeletePanelMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeletePanelMutation = {
  __typename?: 'RootMutationType';
  deletePanel: {__typename?: 'SuccessResult'; success: boolean | null} | null;
};

export type DeleteReloadMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteReloadMutation = {
  __typename?: 'RootMutationType';
  deleteReload: {__typename?: 'SuccessResult'; success: boolean | null} | null;
};

export type DeleteTransactionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteTransactionMutation = {
  __typename?: 'RootMutationType';
  deleteTransaction: {__typename?: 'SuccessResult'; success: boolean | null} | null;
};

export type DeleteUserAccountMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteUserAccountMutation = {
  __typename?: 'RootMutationType';
  deleteUserAccount: {__typename?: 'SuccessResult'; success: boolean | null} | null;
};

export type ReadAllNotificationsMutationVariables = Exact<{[key: string]: never}>;

export type ReadAllNotificationsMutation = {
  __typename?: 'RootMutationType';
  readAllNotifications: {__typename?: 'SuccessResult'; success: boolean | null} | null;
};

export type RefreshTokenMutationVariables = Exact<{[key: string]: never}>;

export type RefreshTokenMutation = {
  __typename?: 'RootMutationType';
  refreshToken: {__typename?: 'AuthResult'; access: string | null} | null;
};

export type ReloadPanelScoreMutationVariables = Exact<{[key: string]: never}>;

export type ReloadPanelScoreMutation = {
  __typename?: 'RootMutationType';
  reloadPanelScore: {__typename?: 'SuccessResult'; success: boolean | null} | null;
};

export type RequestAuthCodeMutationVariables = Exact<{
  username: Scalars['String']['input'];
}>;

export type RequestAuthCodeMutation = {
  __typename?: 'RootMutationType';
  requestAuthCode: {__typename?: 'SuccessResult'; success: boolean | null} | null;
};

export type SignInMutationVariables = Exact<{
  username: Scalars['String']['input'];
  password: InputMaybe<Scalars['String']['input']>;
  code: InputMaybe<Scalars['String']['input']>;
}>;

export type SignInMutation = {
  __typename?: 'RootMutationType';
  signIn: {__typename?: 'AuthResult'; access: string | null} | null;
};

export type SignOutMutationVariables = Exact<{[key: string]: never}>;

export type SignOutMutation = {
  __typename?: 'RootMutationType';
  signOut: {__typename?: 'SuccessResult'; success: boolean | null} | null;
};

export type UpdateCashtagMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  cashtag: InputMaybe<Scalars['String']['input']>;
  status: InputMaybe<CashtagStatus>;
}>;

export type UpdateCashtagMutation = {
  __typename?: 'RootMutationType';
  updateCashtag: {
    __typename?: 'Cashtag';
    cashtag: string | null;
    id: string;
    insertedAt: any | null;
    name: string | null;
    status: CashtagStatus | null;
    updatedAt: any | null;
    createdBy: {__typename?: 'User'; id: string; name: string | null} | null;
    updatedBy: {__typename?: 'User'; id: string; name: string | null} | null;
  } | null;
};

export type UpdateCustomerMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  cwAccountId: InputMaybe<Scalars['String']['input']>;
  cwChatId: InputMaybe<Scalars['String']['input']>;
  cwContactId: InputMaybe<Scalars['String']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  pageId: InputMaybe<Scalars['ID']['input']>;
  status: InputMaybe<CustomerStatus>;
}>;

export type UpdateCustomerMutation = {
  __typename?: 'RootMutationType';
  updateCustomer: {
    __typename?: 'Customer';
    cwAccountId: number | null;
    cwChatId: number | null;
    cwContactId: number | null;
    id: string;
    name: string | null;
    status: CustomerStatus | null;
    updatedAt: any | null;
    page: {__typename?: 'Page'; id: string; name: string | null} | null;
    updatedBy: {__typename?: 'User'; id: string; name: string | null} | null;
  } | null;
};

export type UpdateCustomerAccountMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  username: InputMaybe<Scalars['String']['input']>;
  status: InputMaybe<CustomerAccountStatus>;
  password: InputMaybe<Scalars['String']['input']>;
  panelId: InputMaybe<Scalars['ID']['input']>;
  customerId: InputMaybe<Scalars['ID']['input']>;
}>;

export type UpdateCustomerAccountMutation = {
  __typename?: 'RootMutationType';
  updateCustomerAccount: {
    __typename?: 'CustomerAccount';
    id: string;
    password: string | null;
    status: CustomerAccountStatus | null;
    updatedAt: any | null;
    username: string | null;
    customer: {__typename?: 'Customer'; id: string; name: string | null} | null;
    panel: {__typename?: 'Panel'; id: string; name: string | null} | null;
    updatedBy: {__typename?: 'User'; id: string; name: string | null} | null;
  } | null;
};

export type UpdateGameMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  key: InputMaybe<Scalars['String']['input']>;
  decimal: InputMaybe<Scalars['Boolean']['input']>;
  linkPlay: InputMaybe<Scalars['String']['input']>;
  linkPanel: InputMaybe<Scalars['String']['input']>;
  status: InputMaybe<GameStatus>;
  name: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateGameMutation = {
  __typename?: 'RootMutationType';
  updateGame: {
    __typename?: 'Game';
    id: string;
    key: string | null;
    linkPanel: string | null;
    linkPlay: string | null;
    name: string | null;
    status: GameStatus | null;
    decimal: boolean | null;
    updatedAt: any | null;
    insertedAt: any | null;
  } | null;
};

export type UpdatePageMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  cwId: InputMaybe<Scalars['Int']['input']>;
  fbId: InputMaybe<Scalars['Int']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  status: InputMaybe<ChannelStatus>;
  cwWaInboxId: InputMaybe<Scalars['Int']['input']>;
  waId: InputMaybe<Scalars['Int']['input']>;
  waToken: InputMaybe<Scalars['String']['input']>;
  token: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdatePageMutation = {
  __typename?: 'RootMutationType';
  updatePage: {
    __typename?: 'Page';
    id: string;
    cwId: number | null;
    fbId: number | null;
    name: string | null;
    status: ChannelStatus | null;
    cwWaInboxId: number | null;
    waId: number | null;
    waToken: string | null;
    token: string | null;
    insertedAt: any | null;
  } | null;
};

export type UpdatePageMarketingMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  marketing: InputMaybe<Scalars['Json']['input']>;
}>;

export type UpdatePageMarketingMutation = {
  __typename?: 'RootMutationType';
  updatePage: {__typename?: 'Page'; marketing: any | null} | null;
};

export type UpdatePanelMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name: InputMaybe<Scalars['String']['input']>;
  password: InputMaybe<Scalars['String']['input']>;
  status: InputMaybe<PanelStatus>;
}>;

export type UpdatePanelMutation = {
  __typename?: 'RootMutationType';
  updatePanel: {
    __typename?: 'Panel';
    id: string;
    insertedAt: any | null;
    name: string | null;
    password: string | null;
    score: number | null;
    status: PanelStatus | null;
    updatedAt: any | null;
    username: string | null;
    createdBy: {__typename?: 'User'; id: string; name: string | null} | null;
    game: {__typename?: 'Game'; id: string; name: string | null} | null;
    updatedBy: {__typename?: 'User'; id: string; name: string | null} | null;
  } | null;
};

export type UpdateProfileMutationVariables = Exact<{
  name: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateProfileMutation = {
  __typename?: 'RootMutationType';
  updateProfile: {__typename?: 'User'; id: string; name: string | null} | null;
};

export type UpdateReloadMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  note: InputMaybe<Scalars['String']['input']>;
  panelId: InputMaybe<Scalars['ID']['input']>;
  score: InputMaybe<Scalars['Float']['input']>;
  type: InputMaybe<ReloadType>;
}>;

export type UpdateReloadMutation = {
  __typename?: 'RootMutationType';
  updateReload: {
    __typename?: 'Reload';
    cost: number | null;
    id: string;
    insertedAt: any | null;
    newScore: number | null;
    note: string | null;
    oldScore: number | null;
    score: number | null;
    totalCost: number | null;
    type: ReloadType | null;
    updatedAt: any | null;
    panel: {__typename?: 'Panel'; id: string; name: string | null} | null;
    updatedBy: {__typename?: 'User'; id: string; name: string | null} | null;
  } | null;
};

export type UpdateSessionMutationVariables = Exact<{
  appVersion: InputMaybe<Scalars['String']['input']>;
  browser: InputMaybe<Scalars['String']['input']>;
  browserVersion: InputMaybe<Scalars['String']['input']>;
  langCode: InputMaybe<Scalars['String']['input']>;
  system: InputMaybe<Scalars['String']['input']>;
  systemVersion: InputMaybe<Scalars['String']['input']>;
  timezone: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateSessionMutation = {
  __typename?: 'RootMutationType';
  updateSession: {__typename?: 'Session'; id: string} | null;
};

export type UpdateTransactionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  insertedAt: InputMaybe<Scalars['DateTime']['input']>;
  ourCashtagId: InputMaybe<Scalars['ID']['input']>;
  shift: InputMaybe<TransactionShift>;
  customerCashtag: InputMaybe<Scalars['String']['input']>;
  note: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateTransactionMutation = {
  __typename?: 'RootMutationType';
  updateTransaction: {__typename?: 'Transaction'; id: string; insertedAt: any | null} | null;
};

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  changes: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'RootMutationType';
  updateUser: {
    __typename?: 'User';
    id: string;
    name: string | null;
    status: UserStatuses | null;
    username: string | null;
  } | null;
};

export type UploadFileMutationVariables = Exact<{
  type: FileType;
  file: Scalars['Upload']['input'];
}>;

export type UploadFileMutation = {
  __typename?: 'RootMutationType';
  uploadFile: {
    __typename?: 'File';
    id: string;
    type: FileType | null;
    urls:
      | {__typename?: 'AvatarFile'; thumb: string | null; avatar: string | null}
      | {__typename?: 'PhotoFile'; original: string | null}
      | null;
  } | null;
};

export type UserTransactionsInsightsQueryVariables = Exact<{
  filter: InputMaybe<UserTransactionsInsightsFilter>;
}>;

export type UserTransactionsInsightsQuery = {
  __typename?: 'RootQueryType';
  userTransactionsInsights: Array<{
    __typename?: 'UserTransactionsInsight';
    group: string | null;
    name: string | null;
    value: number | null;
  } | null> | null;
};

export type AvailableLoginMethodsQueryVariables = Exact<{
  username: Scalars['String']['input'];
}>;

export type AvailableLoginMethodsQuery = {
  __typename?: 'RootQueryType';
  availableLoginMethods: Array<LoginMethod | null> | null;
};

export type CashtagsQueryVariables = Exact<{
  first: InputMaybe<Scalars['Int']['input']>;
  filter: InputMaybe<CashtagFilter>;
  order: InputMaybe<CashtagSort>;
  after: InputMaybe<Scalars['String']['input']>;
}>;

export type CashtagsQuery = {
  __typename?: 'RootQueryType';
  cashtags: {
    __typename?: 'CashtagConnection';
    total: number | null;
    pageInfo: {__typename?: 'PageInfo'; endCursor: string | null; hasNextPage: boolean};
    edges: Array<{
      __typename?: 'CashtagEdge';
      node: {
        __typename?: 'Cashtag';
        id: string;
        name: string | null;
        cashtag: string | null;
        status: CashtagStatus | null;
        insertedAt: any | null;
        createdBy: {__typename?: 'User'; id: string; name: string | null} | null;
      } | null;
    } | null> | null;
  } | null;
};

export type CustomerAccountsQueryVariables = Exact<{
  after: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  filter: InputMaybe<CustomerAccountFilter>;
  order: InputMaybe<CustomerAccountSort>;
}>;

export type CustomerAccountsQuery = {
  __typename?: 'RootQueryType';
  customerAccounts: {
    __typename?: 'CustomerAccountConnection';
    total: number | null;
    pageInfo: {__typename?: 'PageInfo'; endCursor: string | null; hasNextPage: boolean};
    edges: Array<{
      __typename?: 'CustomerAccountEdge';
      node: {
        __typename?: 'CustomerAccount';
        id: string;
        insertedAt: any | null;
        password: string | null;
        status: CustomerAccountStatus | null;
        updatedAt: any | null;
        username: string | null;
        createdBy: {__typename?: 'User'; id: string; name: string | null} | null;
        customer: {
          __typename?: 'Customer';
          id: string;
          name: string | null;
          page: {__typename?: 'Page'; id: string; name: string | null} | null;
        } | null;
        panel: {__typename?: 'Panel'; id: string; name: string | null} | null;
        updatedBy: {__typename?: 'User'; id: string; name: string | null} | null;
      } | null;
    } | null> | null;
  } | null;
};

export type CustomerCalcDetailsQueryVariables = Exact<{
  customerId: Scalars['ID']['input'];
  username: Scalars['String']['input'];
  withRewardsAmount: Scalars['Boolean']['input'];
  withRewardsDays: Scalars['Boolean']['input'];
}>;

export type CustomerCalcDetailsQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Cashtag'}
    | {
        __typename?: 'Customer';
        id: string;
        accounts: {
          __typename?: 'CustomerAccountConnection';
          edges: Array<{
            __typename?: 'CustomerAccountEdge';
            node: {
              __typename?: 'CustomerAccount';
              id: string;
              username: string | null;
              latestTransaction: {
                __typename?: 'Transaction';
                id: string;
                amount: number | null;
                bonus: number | null;
                score: number | null;
                type: TransactionType | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
        weekInsights?: {
          __typename?: 'CustomerWeekInsight';
          currentWeek: {
            __typename?: 'CustomerWeekData';
            amount: number | null;
            bonus: number | null;
            count: number | null;
            score: number | null;
          } | null;
          lastWeek: {
            __typename?: 'CustomerWeekData';
            amount: number | null;
            bonus: number | null;
            count: number | null;
            score: number | null;
          } | null;
        } | null;
        weekDaysInsights?: {
          __typename?: 'CustomerWeekDaysInsight';
          currentWeek: number | null;
          lastWeek: number | null;
        } | null;
      }
    | {__typename?: 'CustomerAccount'}
    | {__typename?: 'File'}
    | {__typename?: 'Game'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Page'}
    | {__typename?: 'Panel'}
    | {__typename?: 'Reload'}
    | {__typename?: 'Session'}
    | {__typename?: 'Transaction'}
    | {__typename?: 'User'}
    | null;
};

export type CustomerCountByGameInsightsQueryVariables = Exact<{
  filter: InputMaybe<CustomerCountInsightFilter>;
}>;

export type CustomerCountByGameInsightsQuery = {
  __typename?: 'RootQueryType';
  customerCountByGameInsights: Array<{
    __typename?: 'CustomerCountInsight';
    group: string | null;
    name: string | null;
    value: number | null;
  } | null> | null;
};

export type CustomerCountByPageInsightsQueryVariables = Exact<{
  filter: InputMaybe<CustomerCountInsightFilter>;
}>;

export type CustomerCountByPageInsightsQuery = {
  __typename?: 'RootQueryType';
  customerCountByPageInsights: Array<{
    __typename?: 'CustomerCountInsight';
    group: string | null;
    name: string | null;
    value: number | null;
  } | null> | null;
};

export type CustomerCountInsightsQueryVariables = Exact<{
  filter: InputMaybe<CustomerCountInsightFilter>;
}>;

export type CustomerCountInsightsQuery = {
  __typename?: 'RootQueryType';
  customerCountInsights: Array<{
    __typename?: 'CustomerCountInsight';
    value: number | null;
    name: string | null;
    group: string | null;
  } | null> | null;
};

export type CustomerWeekInsightQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  amount: Scalars['Boolean']['input'];
  day: Scalars['Boolean']['input'];
}>;

export type CustomerWeekInsightQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Cashtag'}
    | {
        __typename?: 'Customer';
        id: string;
        weekInsights?: {
          __typename?: 'CustomerWeekInsight';
          currentWeek: {
            __typename?: 'CustomerWeekData';
            amount: number | null;
            bonus: number | null;
            count: number | null;
            score: number | null;
          } | null;
          lastWeek: {
            __typename?: 'CustomerWeekData';
            amount: number | null;
            bonus: number | null;
            count: number | null;
            score: number | null;
          } | null;
        } | null;
        weekDaysInsights?: {
          __typename?: 'CustomerWeekDaysInsight';
          currentWeek: number | null;
          lastWeek: number | null;
        } | null;
      }
    | {__typename?: 'CustomerAccount'}
    | {__typename?: 'File'}
    | {__typename?: 'Game'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Page'}
    | {__typename?: 'Panel'}
    | {__typename?: 'Reload'}
    | {__typename?: 'Session'}
    | {__typename?: 'Transaction'}
    | {__typename?: 'User'}
    | null;
};

export type CustomersQueryVariables = Exact<{
  after: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  filter: InputMaybe<CustomerFilter>;
  order: InputMaybe<CustomerSort>;
}>;

export type CustomersQuery = {
  __typename?: 'RootQueryType';
  customers: {
    __typename?: 'CustomerConnection';
    total: number | null;
    pageInfo: {__typename?: 'PageInfo'; endCursor: string | null; hasNextPage: boolean};
    edges: Array<{
      __typename?: 'CustomerEdge';
      node: {
        __typename?: 'Customer';
        cwAccountId: number | null;
        cwChatId: number | null;
        cwContactId: number | null;
        id: string;
        insertedAt: any | null;
        name: string | null;
        status: CustomerStatus | null;
        updatedAt: any | null;
        createdBy: {__typename?: 'User'; id: string; name: string | null} | null;
        page: {__typename?: 'Page'; id: string; name: string | null} | null;
        updatedBy: {__typename?: 'User'; id: string; name: string | null} | null;
      } | null;
    } | null> | null;
  } | null;
};

export type FinanceInsightsQueryVariables = Exact<{
  filter: InputMaybe<FinanceInsightFilter>;
}>;

export type FinanceInsightsQuery = {
  __typename?: 'RootQueryType';
  financeInsights: Array<{
    __typename?: 'FinanceInsight';
    group: string | null;
    name: string | null;
    value: number | null;
  } | null> | null;
};

export type GamesQueryVariables = Exact<{
  after: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  filter: InputMaybe<GameFilter>;
  order: InputMaybe<GameSort>;
}>;

export type GamesQuery = {
  __typename?: 'RootQueryType';
  games: {
    __typename?: 'GameConnection';
    total: number | null;
    pageInfo: {__typename?: 'PageInfo'; endCursor: string | null; hasNextPage: boolean};
    edges: Array<{
      __typename?: 'GameEdge';
      node: {
        __typename?: 'Game';
        id: string;
        insertedAt: any | null;
        key: string | null;
        linkPanel: string | null;
        linkPlay: string | null;
        name: string | null;
        status: GameStatus | null;
        updatedAt: any | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetCustomerQueryVariables = Exact<{
  url: InputMaybe<Scalars['String']['input']>;
  accountUsername: InputMaybe<Scalars['String']['input']>;
}>;

export type GetCustomerQuery = {
  __typename?: 'RootQueryType';
  getCustomer: {
    __typename?: 'Customer';
    cwAccountId: number | null;
    cwChatId: number | null;
    cwContactId: number | null;
    id: string;
    insertedAt: any | null;
    latestMarketing: any | null;
    latestMarketingText: string | null;
    name: string | null;
    status: CustomerStatus | null;
    updatedAt: any | null;
    page: {__typename?: 'Page'; id: string; name: string | null} | null;
    createdBy: {__typename?: 'User'; id: string; name: string | null} | null;
  } | null;
};

export type NotificationsQueryVariables = Exact<{
  first: InputMaybe<Scalars['Int']['input']>;
  filter: InputMaybe<NotificationFilter>;
  order: InputMaybe<NotificationSort>;
}>;

export type NotificationsQuery = {
  __typename?: 'RootQueryType';
  viewer: {
    __typename?: 'User';
    id: string;
    notifications: {
      __typename?: 'NotificationConnection';
      unreadCount: number | null;
      pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; hasPreviousPage: boolean};
      edges: Array<{
        __typename?: 'NotificationEdge';
        node: {
          __typename?: 'Notification';
          id: string;
          insertedAt: any | null;
          title: string | null;
          body: string | null;
          type: NotificationType | null;
          resourceId: string | null;
          resourceExtra: string | null;
          read: boolean | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type PagesQueryVariables = Exact<{
  first: InputMaybe<Scalars['Int']['input']>;
  filter: InputMaybe<PageFilter>;
  after: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<PageSort>;
}>;

export type PagesQuery = {
  __typename?: 'RootQueryType';
  pages: {
    __typename?: 'PageConnection';
    total: number | null;
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; endCursor: string | null};
    edges: Array<{
      __typename?: 'PageEdge';
      node: {
        __typename?: 'Page';
        cwId: number | null;
        fbId: number | null;
        id: string;
        insertedAt: any | null;
        name: string | null;
        status: ChannelStatus | null;
        createdBy: {__typename?: 'User'; id: string; name: string | null} | null;
      } | null;
    } | null> | null;
  } | null;
};

export type PagesMarketingQueryVariables = Exact<{
  first: InputMaybe<Scalars['Int']['input']>;
  filter: InputMaybe<PageFilter>;
  after: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<PageSort>;
}>;

export type PagesMarketingQuery = {
  __typename?: 'RootQueryType';
  pages: {
    __typename?: 'PageConnection';
    total: number | null;
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; endCursor: string | null};
    edges: Array<{
      __typename?: 'PageEdge';
      node: {__typename?: 'Page'; id: string; name: string | null; marketing: any | null} | null;
    } | null> | null;
  } | null;
};

export type PagesSimpleQueryVariables = Exact<{
  first: InputMaybe<Scalars['Int']['input']>;
  filter: InputMaybe<PageFilter>;
  after: InputMaybe<Scalars['String']['input']>;
  order: InputMaybe<PageSort>;
}>;

export type PagesSimpleQuery = {
  __typename?: 'RootQueryType';
  pages: {
    __typename?: 'PageConnection';
    total: number | null;
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; endCursor: string | null};
    edges: Array<{
      __typename?: 'PageEdge';
      node: {__typename?: 'Page'; id: string; name: string | null} | null;
    } | null> | null;
  } | null;
};

export type PagesTransactionInsightQueryVariables = Exact<{
  filter: InputMaybe<PagesTransactionInsightFilter>;
}>;

export type PagesTransactionInsightQuery = {
  __typename?: 'RootQueryType';
  pagesTransactionInsight: Array<{
    __typename?: 'PagesTransactionInsightData';
    amount: number | null;
    bonus: number | null;
    pageName: string | null;
    score: number | null;
    tip: number | null;
    type: TransactionType | null;
    count: number | null;
  } | null> | null;
};

export type PanelsQueryVariables = Exact<{
  first: InputMaybe<Scalars['Int']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<PanelFilter>;
  order: InputMaybe<PanelSort>;
  extra: Scalars['Boolean']['input'];
}>;

export type PanelsQuery = {
  __typename?: 'RootQueryType';
  panels: {
    __typename?: 'PanelConnection';
    total: number | null;
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; endCursor: string | null};
    edges: Array<{
      __typename?: 'PanelEdge';
      node: {
        __typename?: 'Panel';
        id: string;
        name: string | null;
        score: number | null;
        status: PanelStatus | null;
        username?: string | null;
        insertedAt?: any | null;
        game?: {__typename?: 'Game'; id: string; name: string | null} | null;
      } | null;
    } | null> | null;
  } | null;
};

export type PanelsSimpleQueryVariables = Exact<{
  first: InputMaybe<Scalars['Int']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<PanelFilter>;
  order: InputMaybe<PanelSort>;
}>;

export type PanelsSimpleQuery = {
  __typename?: 'RootQueryType';
  panels: {
    __typename?: 'PanelConnection';
    total: number | null;
    pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; endCursor: string | null};
    edges: Array<{
      __typename?: 'PanelEdge';
      node: {
        __typename?: 'Panel';
        id: string;
        name: string | null;
        game: {__typename?: 'Game'; id: string; key: string | null} | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ReloadsQueryVariables = Exact<{
  first: InputMaybe<Scalars['Int']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<ReloadFilter>;
  order: InputMaybe<ReloadSort>;
}>;

export type ReloadsQuery = {
  __typename?: 'RootQueryType';
  reloads: {
    __typename?: 'ReloadConnection';
    total: number | null;
    pageInfo: {__typename?: 'PageInfo'; endCursor: string | null; hasNextPage: boolean};
    edges: Array<{
      __typename?: 'ReloadEdge';
      node: {
        __typename?: 'Reload';
        cost: number | null;
        id: string;
        insertedAt: any | null;
        newScore: number | null;
        note: string | null;
        oldScore: number | null;
        score: number | null;
        totalCost: number | null;
        type: ReloadType | null;
        updatedAt: any | null;
        createdBy: {__typename?: 'User'; id: string; name: string | null} | null;
        panel: {__typename?: 'Panel'; id: string; name: string | null} | null;
        updatedBy: {__typename?: 'User'; id: string; name: string | null} | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ShiftTransactionsInsightsQueryVariables = Exact<{
  filter: InputMaybe<ShiftTransactionsInsightsFilter>;
}>;

export type ShiftTransactionsInsightsQuery = {
  __typename?: 'RootQueryType';
  shiftTransactionsInsights: Array<{
    __typename?: 'ShiftTransactionsInsight';
    group: string | null;
    value: number | null;
    name: string | null;
  } | null> | null;
};

export type SingleCashtagQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SingleCashtagQuery = {
  __typename?: 'RootQueryType';
  node:
    | {
        __typename?: 'Cashtag';
        cashtag: string | null;
        id: string;
        insertedAt: any | null;
        name: string | null;
        status: CashtagStatus | null;
        updatedAt: any | null;
        createdBy: {__typename?: 'User'; id: string; name: string | null} | null;
        updatedBy: {__typename?: 'User'; id: string; name: string | null} | null;
      }
    | {__typename?: 'Customer'}
    | {__typename?: 'CustomerAccount'}
    | {__typename?: 'File'}
    | {__typename?: 'Game'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Page'}
    | {__typename?: 'Panel'}
    | {__typename?: 'Reload'}
    | {__typename?: 'Session'}
    | {__typename?: 'Transaction'}
    | {__typename?: 'User'}
    | null;
};

export type SingleCustomerQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SingleCustomerQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Cashtag'}
    | {
        __typename?: 'Customer';
        cwAccountId: number | null;
        cwChatId: number | null;
        cwContactId: number | null;
        id: string;
        insertedAt: any | null;
        name: string | null;
        status: CustomerStatus | null;
        updatedAt: any | null;
        createdBy: {__typename?: 'User'; id: string; name: string | null} | null;
        page: {__typename?: 'Page'; id: string; name: string | null} | null;
        updatedBy: {__typename?: 'User'; id: string; name: string | null} | null;
      }
    | {__typename?: 'CustomerAccount'}
    | {__typename?: 'File'}
    | {__typename?: 'Game'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Page'}
    | {__typename?: 'Panel'}
    | {__typename?: 'Reload'}
    | {__typename?: 'Session'}
    | {__typename?: 'Transaction'}
    | {__typename?: 'User'}
    | null;
};

export type SingleCustomerAccountQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SingleCustomerAccountQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Cashtag'}
    | {__typename?: 'Customer'}
    | {
        __typename?: 'CustomerAccount';
        id: string;
        insertedAt: any | null;
        password: string | null;
        status: CustomerAccountStatus | null;
        updatedAt: any | null;
        username: string | null;
        createdBy: {__typename?: 'User'; id: string; name: string | null} | null;
        customer: {__typename?: 'Customer'; id: string; name: string | null} | null;
        panel: {__typename?: 'Panel'; id: string; name: string | null} | null;
        updatedBy: {__typename?: 'User'; id: string; name: string | null} | null;
      }
    | {__typename?: 'File'}
    | {__typename?: 'Game'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Page'}
    | {__typename?: 'Panel'}
    | {__typename?: 'Reload'}
    | {__typename?: 'Session'}
    | {__typename?: 'Transaction'}
    | {__typename?: 'User'}
    | null;
};

export type SingleCustomerAccountsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
}>;

export type SingleCustomerAccountsQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Cashtag'}
    | {
        __typename?: 'Customer';
        id: string;
        accounts: {
          __typename?: 'CustomerAccountConnection';
          total: number | null;
          pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; endCursor: string | null; hasPreviousPage: boolean};
          edges: Array<{
            __typename?: 'CustomerAccountEdge';
            node: {
              __typename?: 'CustomerAccount';
              id: string;
              username: string | null;
              insertedAt: any | null;
              status: CustomerAccountStatus | null;
              createdBy: {__typename?: 'User'; id: string; name: string | null} | null;
              panel: {
                __typename?: 'Panel';
                id: string;
                name: string | null;
                game: {__typename?: 'Game'; id: string; decimal: boolean | null} | null;
              } | null;
            } | null;
          } | null> | null;
        } | null;
      }
    | {__typename?: 'CustomerAccount'}
    | {__typename?: 'File'}
    | {__typename?: 'Game'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Page'}
    | {__typename?: 'Panel'}
    | {__typename?: 'Reload'}
    | {__typename?: 'Session'}
    | {__typename?: 'Transaction'}
    | {__typename?: 'User'}
    | null;
};

export type SingleCustomerTransactionsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  first: InputMaybe<Scalars['Int']['input']>;
  after: InputMaybe<Scalars['String']['input']>;
  withInsights: Scalars['Boolean']['input'];
}>;

export type SingleCustomerTransactionsQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Cashtag'}
    | {
        __typename?: 'Customer';
        id: string;
        transactionInsights?: Array<{
          __typename?: 'CustomerTranasctionsInsight';
          amount: number | null;
          bonus: number | null;
          count: number | null;
          score: number | null;
          tip: number | null;
          type: TransactionType | null;
        } | null> | null;
        transactions: {
          __typename?: 'TransactionConnection';
          total: number | null;
          pageInfo: {__typename?: 'PageInfo'; hasNextPage: boolean; endCursor: string | null; hasPreviousPage: boolean};
          edges: Array<{
            __typename?: 'TransactionEdge';
            node: {
              __typename?: 'Transaction';
              id: string;
              type: TransactionType | null;
              amount: number | null;
              bonus: number | null;
              note: string | null;
              insertedAt: any | null;
              score: number | null;
              tip: number | null;
              customerCashtag: string | null;
              customerAccount: {
                __typename?: 'CustomerAccount';
                id: string;
                username: string | null;
                panel: {__typename?: 'Panel'; id: string; name: string | null} | null;
              } | null;
              ourCashtag: {__typename?: 'Cashtag'; id: string; cashtag: string | null} | null;
              createdBy: {__typename?: 'User'; id: string; name: string | null} | null;
              files: Array<{
                __typename?: 'File';
                id: string;
                type: FileType | null;
                contentType: string | null;
                urls: {__typename?: 'AvatarFile'} | {__typename?: 'PhotoFile'; original: string | null} | null;
              } | null> | null;
            } | null;
          } | null> | null;
        } | null;
      }
    | {__typename?: 'CustomerAccount'}
    | {__typename?: 'File'}
    | {__typename?: 'Game'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Page'}
    | {__typename?: 'Panel'}
    | {__typename?: 'Reload'}
    | {__typename?: 'Session'}
    | {__typename?: 'Transaction'}
    | {__typename?: 'User'}
    | null;
};

export type SingleGameQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SingleGameQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Cashtag'}
    | {__typename?: 'Customer'}
    | {__typename?: 'CustomerAccount'}
    | {__typename?: 'File'}
    | {
        __typename?: 'Game';
        id: string;
        key: string | null;
        linkPanel: string | null;
        linkPlay: string | null;
        name: string | null;
        decimal: boolean | null;
        status: GameStatus | null;
        updatedAt: any | null;
        insertedAt: any | null;
      }
    | {__typename?: 'Notification'}
    | {__typename?: 'Page'}
    | {__typename?: 'Panel'}
    | {__typename?: 'Reload'}
    | {__typename?: 'Session'}
    | {__typename?: 'Transaction'}
    | {__typename?: 'User'}
    | null;
};

export type SinglePageQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SinglePageQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Cashtag'}
    | {__typename?: 'Customer'}
    | {__typename?: 'CustomerAccount'}
    | {__typename?: 'File'}
    | {__typename?: 'Game'}
    | {__typename?: 'Notification'}
    | {
        __typename?: 'Page';
        id: string;
        cwId: number | null;
        cwWaInboxId: number | null;
        fbId: number | null;
        waId: number | null;
        token: string | null;
        waToken: string | null;
        insertedAt: any | null;
        name: string | null;
        status: ChannelStatus | null;
        updatedAt: any | null;
        createdBy: {__typename?: 'User'; id: string; name: string | null} | null;
        updatedBy: {__typename?: 'User'; id: string; name: string | null} | null;
      }
    | {__typename?: 'Panel'}
    | {__typename?: 'Reload'}
    | {__typename?: 'Session'}
    | {__typename?: 'Transaction'}
    | {__typename?: 'User'}
    | null;
};

export type SinglePanelQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SinglePanelQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Cashtag'}
    | {__typename?: 'Customer'}
    | {__typename?: 'CustomerAccount'}
    | {__typename?: 'File'}
    | {__typename?: 'Game'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Page'}
    | {
        __typename?: 'Panel';
        id: string;
        insertedAt: any | null;
        name: string | null;
        password: string | null;
        score: number | null;
        status: PanelStatus | null;
        updatedAt: any | null;
        username: string | null;
        createdBy: {__typename?: 'User'; id: string; name: string | null} | null;
        game: {__typename?: 'Game'; id: string; name: string | null} | null;
        updatedBy: {__typename?: 'User'; id: string; name: string | null} | null;
      }
    | {__typename?: 'Reload'}
    | {__typename?: 'Session'}
    | {__typename?: 'Transaction'}
    | {__typename?: 'User'}
    | null;
};

export type SingleReloadQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SingleReloadQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Cashtag'}
    | {__typename?: 'Customer'}
    | {__typename?: 'CustomerAccount'}
    | {__typename?: 'File'}
    | {__typename?: 'Game'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Page'}
    | {__typename?: 'Panel'}
    | {
        __typename?: 'Reload';
        cost: number | null;
        id: string;
        insertedAt: any | null;
        newScore: number | null;
        note: string | null;
        oldScore: number | null;
        score: number | null;
        totalCost: number | null;
        type: ReloadType | null;
        updatedAt: any | null;
        createdBy: {__typename?: 'User'; id: string; name: string | null} | null;
        panel: {__typename?: 'Panel'; id: string; name: string | null} | null;
        updatedBy: {__typename?: 'User'; id: string; name: string | null} | null;
      }
    | {__typename?: 'Session'}
    | {__typename?: 'Transaction'}
    | {__typename?: 'User'}
    | null;
};

export type SingleTransactionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SingleTransactionQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Cashtag'}
    | {__typename?: 'Customer'}
    | {__typename?: 'CustomerAccount'}
    | {__typename?: 'File'}
    | {__typename?: 'Game'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Page'}
    | {__typename?: 'Panel'}
    | {__typename?: 'Reload'}
    | {__typename?: 'Session'}
    | {
        __typename?: 'Transaction';
        amount: number | null;
        bonus: number | null;
        freeplay: boolean | null;
        customerCashtag: string | null;
        id: string;
        insertedAt: any | null;
        note: string | null;
        score: number | null;
        shift: TransactionShift | null;
        tip: number | null;
        type: TransactionType | null;
        updatedAt: any | null;
        createdBy: {__typename?: 'User'; id: string; name: string | null} | null;
        customer: {__typename?: 'Customer'; id: string; name: string | null} | null;
        customerAccount: {__typename?: 'CustomerAccount'; id: string; username: string | null} | null;
        files: Array<{
          __typename?: 'File';
          id: string;
          type: FileType | null;
          hash: string | null;
          filename: string | null;
          contentType: string | null;
          urls: {__typename?: 'AvatarFile'} | {__typename?: 'PhotoFile'; original: string | null} | null;
        } | null> | null;
        ourCashtag: {__typename?: 'Cashtag'; id: string; name: string | null} | null;
        updatedBy: {__typename?: 'User'; id: string; name: string | null} | null;
      }
    | {__typename?: 'User'}
    | null;
};

export type SingleUserQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type SingleUserQuery = {
  __typename?: 'RootQueryType';
  node:
    | {__typename?: 'Cashtag'}
    | {__typename?: 'Customer'}
    | {__typename?: 'CustomerAccount'}
    | {__typename?: 'File'}
    | {__typename?: 'Game'}
    | {__typename?: 'Notification'}
    | {__typename?: 'Page'}
    | {__typename?: 'Panel'}
    | {__typename?: 'Reload'}
    | {__typename?: 'Session'}
    | {__typename?: 'Transaction'}
    | {
        __typename?: 'User';
        id: string;
        name: string | null;
        role: UserRoles | null;
        status: UserStatuses | null;
        username: string | null;
        insertedAt: any | null;
      }
    | null;
};

export type TransactionsQueryVariables = Exact<{
  after: InputMaybe<Scalars['String']['input']>;
  filter: InputMaybe<TransactionFilter>;
  order: InputMaybe<TransactionSort>;
  first: InputMaybe<Scalars['Int']['input']>;
}>;

export type TransactionsQuery = {
  __typename?: 'RootQueryType';
  transactions: {
    __typename?: 'TransactionConnection';
    total: number | null;
    pageInfo: {__typename?: 'PageInfo'; endCursor: string | null; hasNextPage: boolean};
    edges: Array<{
      __typename?: 'TransactionEdge';
      node: {
        __typename?: 'Transaction';
        amount: number | null;
        bonus: number | null;
        customerCashtag: string | null;
        id: string;
        insertedAt: any | null;
        note: string | null;
        score: number | null;
        shift: TransactionShift | null;
        tip: number | null;
        type: TransactionType | null;
        updatedAt: any | null;
        createdBy: {__typename?: 'User'; id: string; name: string | null} | null;
        customer: {__typename?: 'Customer'; id: string; name: string | null} | null;
        customerAccount: {
          __typename?: 'CustomerAccount';
          id: string;
          username: string | null;
          panel: {__typename?: 'Panel'; id: string; name: string | null} | null;
        } | null;
        ourCashtag: {__typename?: 'Cashtag'; id: string; name: string | null; cashtag: string | null} | null;
        updatedBy: {__typename?: 'User'; id: string; name: string | null} | null;
        files: Array<{
          __typename?: 'File';
          id: string;
          type: FileType | null;
          contentType: string | null;
          urls: {__typename?: 'AvatarFile'} | {__typename?: 'PhotoFile'; original: string | null} | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type UsersQueryVariables = Exact<{
  after: InputMaybe<Scalars['String']['input']>;
  first: InputMaybe<Scalars['Int']['input']>;
  order: InputMaybe<UserSort>;
  filter: InputMaybe<UserFilter>;
}>;

export type UsersQuery = {
  __typename?: 'RootQueryType';
  users: {
    __typename?: 'UserConnection';
    total: number | null;
    pageInfo: {__typename?: 'PageInfo'; endCursor: string | null; hasNextPage: boolean; hasPreviousPage: boolean};
    edges: Array<{
      __typename?: 'UserEdge';
      node:
        | ({__typename?: 'User'; role: UserRoles | null; status: UserStatuses | null; insertedAt: any | null} & {
            ' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment};
          })
        | null;
    } | null> | null;
  } | null;
};

export type BasicProfileFragment = {__typename?: 'User'; id: string; name: string | null; username: string | null} & {
  ' $fragmentName'?: 'BasicProfileFragment';
};

export type ViewerQueryVariables = Exact<{[key: string]: never}>;

export type ViewerQuery = {
  __typename?: 'RootQueryType';
  viewer:
    | ({__typename?: 'User'; role: UserRoles | null; status: UserStatuses | null; insertedAt: any | null} & {
        ' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment};
      })
    | null;
};

export type ViewerProfileQueryVariables = Exact<{[key: string]: never}>;

export type ViewerProfileQuery = {
  __typename?: 'RootQueryType';
  viewer:
    | ({__typename?: 'User'; role: UserRoles | null; status: UserStatuses | null; insertedAt: any | null} & {
        ' $fragmentRefs'?: {BasicProfileFragment: BasicProfileFragment};
      })
    | null;
};

export const BasicProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'username'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<BasicProfileFragment, unknown>;
export const CreateCashtagDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createCashtag'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'cashtag'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'CashtagStatus'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createCashtag'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'cashtag'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'cashtag'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'name'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'status'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCashtagMutation, CreateCashtagMutationVariables>;
export const CreateCustomerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createCustomer'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'cwAccountId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'cwChatId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'cwContactId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'pageId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'CustomerStatus'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createCustomer'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'cwAccountId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'cwAccountId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'cwChatId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'cwChatId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'cwContactId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'cwContactId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'name'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'pageId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'status'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const CreateCustomerAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createCustomerAccount'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'customerId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'panelId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'password'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'CustomerAccountStatus'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'username'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createCustomerAccount'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'customerId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'customerId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'panelId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'panelId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'password'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'password'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'status'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'username'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'username'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCustomerAccountMutation, CreateCustomerAccountMutationVariables>;
export const CreateGameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createGame'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'key'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'GameStatus'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'decimal'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'linkPanel'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'linkPlay'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createGame'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'key'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'key'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'name'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'status'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'decimal'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'decimal'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'linkPanel'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'linkPanel'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'linkPlay'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'linkPlay'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateGameMutation, CreateGameMutationVariables>;
export const CreatePageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createPage'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ChannelStatus'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'cwId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'fbId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createPage'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'name'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'status'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'cwId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'cwId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'fbId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'fbId'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePageMutation, CreatePageMutationVariables>;
export const CreatePanelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createPanel'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'gameId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'PanelStatus'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'username'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'password'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createPanel'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'gameId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'gameId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'status'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'username'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'username'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'password'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'password'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'name'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePanelMutation, CreatePanelMutationVariables>;
export const CreateReloadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createReload'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'cost'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Float'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'panelId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'score'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'Float'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ReloadType'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createReload'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'cost'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'cost'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'panelId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'panelId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'score'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'score'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'type'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateReloadMutation, CreateReloadMutationVariables>;
export const CreateTransactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createTransaction'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'customerAccountId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'customerId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Float'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'bonus'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Float'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'customerCashtag'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'freeplay'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'note'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'ourCashtagId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'score'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'Float'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'tip'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Float'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'TransactionType'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'shift'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'TransactionShift'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'files'}},
          type: {
            kind: 'ListType',
            type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createTransaction'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'customerAccountId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'customerAccountId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'customerId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'customerId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'amount'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'bonus'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'bonus'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'customerCashtag'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'customerCashtag'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'freeplay'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'freeplay'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'note'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'note'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'ourCashtagId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'ourCashtagId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'score'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'score'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'tip'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'tip'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'type'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'shift'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'shift'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'files'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'files'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateTransactionMutation, CreateTransactionMutationVariables>;
export const CreateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'createUser'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'username'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'password'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'role'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'UserRoles'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'UserStatuses'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'createUser'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'name'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'username'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'username'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'password'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'password'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'role'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'role'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'status'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteCashtagDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'deleteCashtag'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'deleteCashtag'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteCashtagMutation, DeleteCashtagMutationVariables>;
export const DeleteCustomerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'deleteCustomer'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'deleteCustomer'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteCustomerMutation, DeleteCustomerMutationVariables>;
export const DeleteCustomerAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'deleteCustomerAccount'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'deleteCustomerAccount'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteCustomerAccountMutation, DeleteCustomerAccountMutationVariables>;
export const DeleteGameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'deleteGame'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'deleteGame'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteGameMutation, DeleteGameMutationVariables>;
export const DeletePageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'deletePage'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'deletePage'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeletePageMutation, DeletePageMutationVariables>;
export const DeletePanelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'deletePanel'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'deletePanel'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeletePanelMutation, DeletePanelMutationVariables>;
export const DeleteReloadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'deleteReload'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'deleteReload'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteReloadMutation, DeleteReloadMutationVariables>;
export const DeleteTransactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'deleteTransaction'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'deleteTransaction'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteTransactionMutation, DeleteTransactionMutationVariables>;
export const DeleteUserAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'deleteUserAccount'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'deleteUserAccount'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'userId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteUserAccountMutation, DeleteUserAccountMutationVariables>;
export const ReadAllNotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'readAllNotifications'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'readAllNotifications'},
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReadAllNotificationsMutation, ReadAllNotificationsMutationVariables>;
export const RefreshTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'refreshToken'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'refreshToken'},
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'access'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const ReloadPanelScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'reloadPanelScore'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'reloadPanelScore'},
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReloadPanelScoreMutation, ReloadPanelScoreMutationVariables>;
export const RequestAuthCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'requestAuthCode'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'username'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'requestAuthCode'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'username'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'username'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RequestAuthCodeMutation, RequestAuthCodeMutationVariables>;
export const SignInDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'signIn'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'username'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'password'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'code'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'signIn'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'username'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'username'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'password'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'password'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'code'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'code'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'access'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SignInMutation, SignInMutationVariables>;
export const SignOutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'signOut'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'signOut'},
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'success'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SignOutMutation, SignOutMutationVariables>;
export const UpdateCashtagDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updateCashtag'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'cashtag'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'CashtagStatus'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateCashtag'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'name'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'cashtag'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'cashtag'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'status'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'cashtag'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'createdBy'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'updatedBy'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCashtagMutation, UpdateCashtagMutationVariables>;
export const UpdateCustomerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updateCustomer'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'cwAccountId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'cwChatId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'cwContactId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'pageId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'CustomerStatus'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateCustomer'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'cwAccountId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'cwAccountId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'cwChatId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'cwChatId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'cwContactId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'cwContactId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'name'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'pageId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'pageId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'status'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'cwAccountId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'cwChatId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'cwContactId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'page'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'updatedBy'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const UpdateCustomerAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updateCustomerAccount'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'username'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'CustomerAccountStatus'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'password'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'panelId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'customerId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateCustomerAccount'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'username'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'username'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'status'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'password'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'password'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'panelId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'panelId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'customerId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'customerId'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'customer'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'panel'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
                {kind: 'Field', name: {kind: 'Name', value: 'password'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'updatedBy'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
                {kind: 'Field', name: {kind: 'Name', value: 'username'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCustomerAccountMutation, UpdateCustomerAccountMutationVariables>;
export const UpdateGameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updateGame'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'key'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'decimal'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'linkPlay'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'linkPanel'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'GameStatus'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateGame'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'key'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'key'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'linkPlay'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'linkPlay'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'linkPanel'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'linkPanel'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'status'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'decimal'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'decimal'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'name'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'key'}},
                {kind: 'Field', name: {kind: 'Name', value: 'linkPanel'}},
                {kind: 'Field', name: {kind: 'Name', value: 'linkPlay'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {kind: 'Field', name: {kind: 'Name', value: 'decimal'}},
                {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateGameMutation, UpdateGameMutationVariables>;
export const UpdatePageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updatePage'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'cwId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'fbId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ChannelStatus'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'cwWaInboxId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'waId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'waToken'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'token'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updatePage'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'cwId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'cwId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'fbId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'fbId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'name'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'status'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'cwWaInboxId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'cwWaInboxId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'waId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'waId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'waToken'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'waToken'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'token'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'token'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'cwId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'fbId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {kind: 'Field', name: {kind: 'Name', value: 'cwWaInboxId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'waId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'waToken'}},
                {kind: 'Field', name: {kind: 'Name', value: 'token'}},
                {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePageMutation, UpdatePageMutationVariables>;
export const UpdatePageMarketingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updatePageMarketing'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'marketing'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Json'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updatePage'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'marketing'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'marketing'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'marketing'}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePageMarketingMutation, UpdatePageMarketingMutationVariables>;
export const UpdatePanelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updatePanel'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'password'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'PanelStatus'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updatePanel'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'name'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'password'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'password'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'status'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'status'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'createdBy'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'game'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'password'}},
                {kind: 'Field', name: {kind: 'Name', value: 'score'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'updatedBy'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
                {kind: 'Field', name: {kind: 'Name', value: 'username'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePanelMutation, UpdatePanelMutationVariables>;
export const UpdateProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updateProfile'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateProfile'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'name'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'name'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const UpdateReloadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updateReload'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'note'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'panelId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'score'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Float'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ReloadType'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateReload'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'note'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'note'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'panelId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'panelId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'score'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'score'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'type'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'cost'}},
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'newScore'}},
                {kind: 'Field', name: {kind: 'Name', value: 'note'}},
                {kind: 'Field', name: {kind: 'Name', value: 'oldScore'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'panel'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
                {kind: 'Field', name: {kind: 'Name', value: 'score'}},
                {kind: 'Field', name: {kind: 'Name', value: 'totalCost'}},
                {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'updatedBy'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateReloadMutation, UpdateReloadMutationVariables>;
export const UpdateSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updateSession'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'appVersion'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'browser'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'browserVersion'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'langCode'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'system'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'systemVersion'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'timezone'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateSession'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'appVersion'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'appVersion'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'browser'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'browser'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'browserVersion'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'browserVersion'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'langCode'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'langCode'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'system'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'system'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'systemVersion'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'systemVersion'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'timezone'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'timezone'}},
              },
            ],
            selectionSet: {kind: 'SelectionSet', selections: [{kind: 'Field', name: {kind: 'Name', value: 'id'}}]},
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSessionMutation, UpdateSessionMutationVariables>;
export const UpdateTransactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updateTransaction'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'insertedAt'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'DateTime'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'ourCashtagId'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'shift'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'TransactionShift'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'customerCashtag'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'note'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateTransaction'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'insertedAt'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'insertedAt'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'ourCashtagId'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'ourCashtagId'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'shift'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'shift'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'customerCashtag'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'customerCashtag'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'note'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'note'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateTransactionMutation, UpdateTransactionMutationVariables>;
export const UpdateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'updateUser'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'changes'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'UpdateUserInput'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'updateUser'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'changes'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'changes'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {kind: 'Field', name: {kind: 'Name', value: 'username'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
export const UploadFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'uploadFile'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'FileType'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'file'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'Upload'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'uploadFile'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'type'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'type'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'file'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'file'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'AvatarFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'thumb'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'avatar'}},
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'urls'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'PhotoFile'}},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{kind: 'Field', name: {kind: 'Name', value: 'original'}}],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UploadFileMutation, UploadFileMutationVariables>;
export const UserTransactionsInsightsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'userTransactionsInsights'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'UserTransactionsInsightsFilter'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'userTransactionsInsights'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'group'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'value'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserTransactionsInsightsQuery, UserTransactionsInsightsQueryVariables>;
export const AvailableLoginMethodsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'availableLoginMethods'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'username'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'availableLoginMethods'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'username'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'username'}},
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AvailableLoginMethodsQuery, AvailableLoginMethodsQueryVariables>;
export const CashtagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'cashtags'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'CashtagFilter'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'CashtagSort'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'cashtags'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'first'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'order'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'after'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'total'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pageInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'edges'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'node'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'cashtag'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'createdBy'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                ],
                              },
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CashtagsQuery, CashtagsQueryVariables>;
export const CustomerAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'customerAccounts'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'CustomerAccountFilter'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'CustomerAccountSort'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'customerAccounts'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'after'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'first'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'order'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'total'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pageInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'edges'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'node'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'createdBy'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'customer'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'page'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'panel'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                ],
                              },
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'password'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'updatedBy'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                ],
                              },
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'username'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomerAccountsQuery, CustomerAccountsQueryVariables>;
export const CustomerCalcDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'customerCalcDetails'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'customerId'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'username'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'withRewardsAmount'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'withRewardsDays'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'customerId'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Customer'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'accounts'},
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'first'},
                            value: {kind: 'IntValue', value: '1'},
                          },
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'filter'},
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {kind: 'Name', value: 'username'},
                                  value: {kind: 'Variable', name: {kind: 'Name', value: 'username'}},
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'edges'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'node'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'username'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'latestTransaction'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                                              {kind: 'Field', name: {kind: 'Name', value: 'bonus'}},
                                              {kind: 'Field', name: {kind: 'Name', value: 'score'}},
                                              {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'weekInsights'},
                        directives: [
                          {
                            kind: 'Directive',
                            name: {kind: 'Name', value: 'include'},
                            arguments: [
                              {
                                kind: 'Argument',
                                name: {kind: 'Name', value: 'if'},
                                value: {kind: 'Variable', name: {kind: 'Name', value: 'withRewardsAmount'}},
                              },
                            ],
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'currentWeek'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'bonus'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'count'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'score'}},
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'lastWeek'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'bonus'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'count'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'score'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'weekDaysInsights'},
                        directives: [
                          {
                            kind: 'Directive',
                            name: {kind: 'Name', value: 'include'},
                            arguments: [
                              {
                                kind: 'Argument',
                                name: {kind: 'Name', value: 'if'},
                                value: {kind: 'Variable', name: {kind: 'Name', value: 'withRewardsDays'}},
                              },
                            ],
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'currentWeek'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'lastWeek'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomerCalcDetailsQuery, CustomerCalcDetailsQueryVariables>;
export const CustomerCountByGameInsightsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'customerCountByGameInsights'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'CustomerCountInsightFilter'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'customerCountByGameInsights'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'group'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'value'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomerCountByGameInsightsQuery, CustomerCountByGameInsightsQueryVariables>;
export const CustomerCountByPageInsightsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'customerCountByPageInsights'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'CustomerCountInsightFilter'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'customerCountByPageInsights'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'group'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'value'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomerCountByPageInsightsQuery, CustomerCountByPageInsightsQueryVariables>;
export const CustomerCountInsightsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'CustomerCountInsights'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'CustomerCountInsightFilter'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'customerCountInsights'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'value'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'group'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomerCountInsightsQuery, CustomerCountInsightsQueryVariables>;
export const CustomerWeekInsightDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'customerWeekInsight'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'day'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Customer'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'weekInsights'},
                        directives: [
                          {
                            kind: 'Directive',
                            name: {kind: 'Name', value: 'include'},
                            arguments: [
                              {
                                kind: 'Argument',
                                name: {kind: 'Name', value: 'if'},
                                value: {kind: 'Variable', name: {kind: 'Name', value: 'amount'}},
                              },
                            ],
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'currentWeek'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'bonus'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'count'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'score'}},
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'lastWeek'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'bonus'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'count'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'score'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'weekDaysInsights'},
                        directives: [
                          {
                            kind: 'Directive',
                            name: {kind: 'Name', value: 'include'},
                            arguments: [
                              {
                                kind: 'Argument',
                                name: {kind: 'Name', value: 'if'},
                                value: {kind: 'Variable', name: {kind: 'Name', value: 'day'}},
                              },
                            ],
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'currentWeek'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'lastWeek'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomerWeekInsightQuery, CustomerWeekInsightQueryVariables>;
export const CustomersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'customers'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'CustomerFilter'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'CustomerSort'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'customers'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'after'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'first'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'order'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'total'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pageInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'edges'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'node'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'createdBy'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                ],
                              },
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'cwAccountId'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'cwChatId'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'cwContactId'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'page'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                ],
                              },
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'updatedBy'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomersQuery, CustomersQueryVariables>;
export const FinanceInsightsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'financeInsights'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'FinanceInsightFilter'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'financeInsights'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'group'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'value'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FinanceInsightsQuery, FinanceInsightsQueryVariables>;
export const GamesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'games'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'GameFilter'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'GameSort'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'games'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'after'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'first'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'order'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'total'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pageInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'edges'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'node'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'key'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'linkPanel'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'linkPlay'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GamesQuery, GamesQueryVariables>;
export const GetCustomerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'getCustomer'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'url'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'accountUsername'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'getCustomer'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'url'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'url'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'accountUsername'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'accountUsername'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'cwAccountId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'cwChatId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'cwContactId'}},
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                {kind: 'Field', name: {kind: 'Name', value: 'latestMarketing'}},
                {kind: 'Field', name: {kind: 'Name', value: 'latestMarketingText'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'page'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'createdBy'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCustomerQuery, GetCustomerQueryVariables>;
export const NotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'notifications'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'NotificationFilter'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'NotificationSort'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'viewer'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'notifications'},
                  arguments: [
                    {
                      kind: 'Argument',
                      name: {kind: 'Name', value: 'first'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
                    },
                    {
                      kind: 'Argument',
                      name: {kind: 'Name', value: 'filter'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
                    },
                    {
                      kind: 'Argument',
                      name: {kind: 'Name', value: 'order'},
                      value: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'unreadCount'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'pageInfo'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'edges'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'node'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'title'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'body'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'resourceId'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'resourceExtra'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'read'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NotificationsQuery, NotificationsQueryVariables>;
export const PagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'pages'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'PageFilter'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'PageSort'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'pages'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'first'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'after'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'order'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'total'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pageInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'edges'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'node'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'cwId'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'fbId'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'createdBy'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                ],
                              },
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PagesQuery, PagesQueryVariables>;
export const PagesMarketingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'pagesMarketing'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'PageFilter'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'PageSort'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'pages'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'first'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'after'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'order'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'total'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pageInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'edges'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'node'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'marketing'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PagesMarketingQuery, PagesMarketingQueryVariables>;
export const PagesSimpleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'pagesSimple'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'PageFilter'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'PageSort'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'pages'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'first'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'after'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'order'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'total'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pageInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'edges'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'node'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PagesSimpleQuery, PagesSimpleQueryVariables>;
export const PagesTransactionInsightDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'pagesTransactionInsight'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'PagesTransactionInsightFilter'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'pagesTransactionInsight'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                {kind: 'Field', name: {kind: 'Name', value: 'bonus'}},
                {kind: 'Field', name: {kind: 'Name', value: 'pageName'}},
                {kind: 'Field', name: {kind: 'Name', value: 'score'}},
                {kind: 'Field', name: {kind: 'Name', value: 'tip'}},
                {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                {kind: 'Field', name: {kind: 'Name', value: 'count'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PagesTransactionInsightQuery, PagesTransactionInsightQueryVariables>;
export const PanelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'panels'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'PanelFilter'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'PanelSort'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'extra'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'panels'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'first'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'after'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'order'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'total'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pageInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'edges'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'node'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'score'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'username'},
                              directives: [
                                {
                                  kind: 'Directive',
                                  name: {kind: 'Name', value: 'include'},
                                  arguments: [
                                    {
                                      kind: 'Argument',
                                      name: {kind: 'Name', value: 'if'},
                                      value: {kind: 'Variable', name: {kind: 'Name', value: 'extra'}},
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'insertedAt'},
                              directives: [
                                {
                                  kind: 'Directive',
                                  name: {kind: 'Name', value: 'include'},
                                  arguments: [
                                    {
                                      kind: 'Argument',
                                      name: {kind: 'Name', value: 'if'},
                                      value: {kind: 'Variable', name: {kind: 'Name', value: 'extra'}},
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'game'},
                              directives: [
                                {
                                  kind: 'Directive',
                                  name: {kind: 'Name', value: 'include'},
                                  arguments: [
                                    {
                                      kind: 'Argument',
                                      name: {kind: 'Name', value: 'if'},
                                      value: {kind: 'Variable', name: {kind: 'Name', value: 'extra'}},
                                    },
                                  ],
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PanelsQuery, PanelsQueryVariables>;
export const PanelsSimpleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'panelsSimple'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'PanelFilter'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'PanelSort'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'panels'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'first'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'after'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'order'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'total'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pageInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'edges'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'node'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'game'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'key'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PanelsSimpleQuery, PanelsSimpleQueryVariables>;
export const ReloadsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'reloads'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ReloadFilter'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ReloadSort'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'reloads'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'first'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'after'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'order'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'total'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pageInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'edges'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'node'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'cost'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'createdBy'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                ],
                              },
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'newScore'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'note'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'oldScore'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'panel'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                ],
                              },
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'score'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'totalCost'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'updatedBy'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReloadsQuery, ReloadsQueryVariables>;
export const ShiftTransactionsInsightsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'shiftTransactionsInsights'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'ShiftTransactionsInsightsFilter'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'shiftTransactionsInsights'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'group'}},
                {kind: 'Field', name: {kind: 'Name', value: 'value'}},
                {kind: 'Field', name: {kind: 'Name', value: 'name'}},
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShiftTransactionsInsightsQuery, ShiftTransactionsInsightsQueryVariables>;
export const SingleCashtagDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singleCashtag'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Cashtag'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'cashtag'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'createdBy'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                          ],
                        },
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'updatedBy'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SingleCashtagQuery, SingleCashtagQueryVariables>;
export const SingleCustomerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singleCustomer'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Customer'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'createdBy'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                          ],
                        },
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'cwAccountId'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'cwChatId'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'cwContactId'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'page'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                          ],
                        },
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'updatedBy'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SingleCustomerQuery, SingleCustomerQueryVariables>;
export const SingleCustomerAccountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singleCustomerAccount'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'CustomerAccount'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'createdBy'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'customer'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                          ],
                        },
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'panel'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                          ],
                        },
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'password'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'updatedBy'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                          ],
                        },
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'username'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SingleCustomerAccountQuery, SingleCustomerAccountQueryVariables>;
export const SingleCustomerAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singleCustomerAccounts'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Customer'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'accounts'},
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'first'},
                            value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
                          },
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'after'},
                            value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'total'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'pageInfo'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'edges'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'node'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'createdBy'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'panel'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'game'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                                    {kind: 'Field', name: {kind: 'Name', value: 'decimal'}},
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {kind: 'Field', name: {kind: 'Name', value: 'username'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SingleCustomerAccountsQuery, SingleCustomerAccountsQueryVariables>;
export const SingleCustomerTransactionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singleCustomerTransactions'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'withInsights'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'Boolean'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Customer'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'transactionInsights'},
                        directives: [
                          {
                            kind: 'Directive',
                            name: {kind: 'Name', value: 'include'},
                            arguments: [
                              {
                                kind: 'Argument',
                                name: {kind: 'Name', value: 'if'},
                                value: {kind: 'Variable', name: {kind: 'Name', value: 'withInsights'}},
                              },
                            ],
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'bonus'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'count'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'score'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'tip'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'transactions'},
                        arguments: [
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'first'},
                            value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
                          },
                          {
                            kind: 'Argument',
                            name: {kind: 'Name', value: 'after'},
                            value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'total'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'pageInfo'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'edges'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'node'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'bonus'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'note'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'score'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'tip'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'customerCashtag'}},
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'customerAccount'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {kind: 'Field', name: {kind: 'Name', value: 'username'}},
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'panel'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                                    {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'ourCashtag'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {kind: 'Field', name: {kind: 'Name', value: 'cashtag'}},
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'createdBy'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'files'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                              {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                                              {kind: 'Field', name: {kind: 'Name', value: 'contentType'}},
                                              {
                                                kind: 'Field',
                                                name: {kind: 'Name', value: 'urls'},
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'InlineFragment',
                                                      typeCondition: {
                                                        kind: 'NamedType',
                                                        name: {kind: 'Name', value: 'PhotoFile'},
                                                      },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          {kind: 'Field', name: {kind: 'Name', value: 'original'}},
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SingleCustomerTransactionsQuery, SingleCustomerTransactionsQueryVariables>;
export const SingleGameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singleGame'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Game'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'key'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'linkPanel'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'linkPlay'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'decimal'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SingleGameQuery, SingleGameQueryVariables>;
export const SinglePageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singlePage'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Page'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'createdBy'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                          ],
                        },
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'cwId'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'cwWaInboxId'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'fbId'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'waId'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'token'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'waToken'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'updatedBy'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SinglePageQuery, SinglePageQueryVariables>;
export const SinglePanelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singlePanel'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Panel'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'createdBy'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'game'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                          ],
                        },
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'password'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'score'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'updatedBy'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                          ],
                        },
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'username'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SinglePanelQuery, SinglePanelQueryVariables>;
export const SingleReloadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singleReload'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Reload'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'cost'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'createdBy'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                          ],
                        },
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'newScore'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'note'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'oldScore'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'panel'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                          ],
                        },
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'score'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'totalCost'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'updatedBy'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SingleReloadQuery, SingleReloadQueryVariables>;
export const SingleTransactionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singleTransaction'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'Transaction'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'bonus'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'freeplay'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'createdBy'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'customer'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'customerAccount'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'username'}},
                          ],
                        },
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'customerCashtag'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'files'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'urls'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'PhotoFile'}},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{kind: 'Field', name: {kind: 'Name', value: 'original'}}],
                                    },
                                  },
                                ],
                              },
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'hash'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'filename'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'contentType'}},
                          ],
                        },
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'note'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'ourCashtag'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                          ],
                        },
                      },
                      {kind: 'Field', name: {kind: 'Name', value: 'score'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'shift'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'tip'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'updatedBy'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SingleTransactionQuery, SingleTransactionQueryVariables>;
export const SingleUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'singleUser'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
          type: {kind: 'NonNullType', type: {kind: 'NamedType', name: {kind: 'Name', value: 'ID'}}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'node'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'id'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'id'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'role'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'username'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SingleUserQuery, SingleUserQueryVariables>;
export const TransactionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'transactions'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'TransactionFilter'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'TransactionSort'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'transactions'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'after'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'order'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'first'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'total'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pageInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'edges'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'node'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'amount'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'bonus'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'createdBy'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'customer'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'customerAccount'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'username'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'panel'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                        {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'customerCashtag'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'note'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'ourCashtag'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'cashtag'}},
                                ],
                              },
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'score'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'shift'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'tip'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'updatedAt'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'updatedBy'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'name'}},
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'files'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'contentType'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'urls'},
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'PhotoFile'}},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [{kind: 'Field', name: {kind: 'Name', value: 'original'}}],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TransactionsQuery, TransactionsQueryVariables>;
export const UsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'users'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'String'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'Int'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'UserSort'}},
        },
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
          type: {kind: 'NamedType', name: {kind: 'Name', value: 'UserFilter'}},
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'users'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'after'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'after'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'first'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'first'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'order'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'order'}},
              },
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'filter'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'filter'}},
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'total'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'pageInfo'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {kind: 'Field', name: {kind: 'Name', value: 'endCursor'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'hasNextPage'}},
                      {kind: 'Field', name: {kind: 'Name', value: 'hasPreviousPage'}},
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'edges'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'node'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'role'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'username'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<UsersQuery, UsersQueryVariables>;
export const ViewerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'viewer'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'viewer'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}},
                {kind: 'Field', name: {kind: 'Name', value: 'role'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'username'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<ViewerQuery, ViewerQueryVariables>;
export const ViewerProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'viewerProfile'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'viewer'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'FragmentSpread', name: {kind: 'Name', value: 'BasicProfile'}},
                {kind: 'Field', name: {kind: 'Name', value: 'role'}},
                {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                {kind: 'Field', name: {kind: 'Name', value: 'insertedAt'}},
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {kind: 'Name', value: 'BasicProfile'},
      typeCondition: {kind: 'NamedType', name: {kind: 'Name', value: 'User'}},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {kind: 'Field', name: {kind: 'Name', value: 'id'}},
          {kind: 'Field', name: {kind: 'Name', value: 'name'}},
          {kind: 'Field', name: {kind: 'Name', value: 'username'}},
        ],
      },
    },
  ],
} as unknown as DocumentNode<ViewerProfileQuery, ViewerProfileQueryVariables>;
