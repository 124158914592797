import {User} from '@gql/graphql';

export const getUniqueColor = ({id}: User) => {
  const colors = [
    '#e51c23',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#5677fc',
    '#03a9f4',
    '#00bcd4',
    '#009688',
    '#259b24',
    '#8bc34a',
    '#afb42b',
    '#ff9800',
    '#ff5722',
    '#795548',
    '#607d8b',
  ];

  if (id.length === 0) return colors[0];
  let hash = 0;
  for (let i = 0; i < id.length; i++) {
    hash = id.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  hash = ((hash % colors.length) + colors.length) % colors.length;
  return colors[hash];
};

export const getFirstLetter = (viewer: User) => {
  let initial = '';
  if (viewer.name) {
    initial = viewer.name
      .split(' ')
      .map(n => n[0])
      .slice(0, 2)
      .join('');
  }

  return initial;
};

export const getFirstName = (viewer: User) => {
  let name = '';
  if (viewer.name) name += viewer.name.split(' ')[0];
  return name;
};
