import {CompressOutlined, ExpandOutlined} from '@ant-design/icons';
import {useReactiveVar} from '@apollo/client';
import {compactMode} from '@app/utils/theme';

const CompactSwitch = () => {
  const mode = useReactiveVar(compactMode);

  const toggleCompact = () => {
    if (mode == 'COMPACT') {
      compactMode('NOCOMPACT');
    } else {
      compactMode('COMPACT');
    }
  };
  return (
    <div className="right" onClick={toggleCompact}>
      <span className="action">
        {mode == 'NOCOMPACT' && <CompressOutlined />}
        {mode == 'COMPACT' && <ExpandOutlined />}
      </span>
    </div>
  );
};

export default CompactSwitch;
