import {ArrowLeftOutlined, HomeOutlined, ReloadOutlined, SmileOutlined} from '@ant-design/icons';
import {Trans, msg} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {Button, Layout, Result} from 'antd';
import {isRouteErrorResponse, useNavigate, useRouteError} from 'react-router-dom';
import {Fragment} from 'react/jsx-runtime';

interface IError extends Error {
  message: string;
}

const importModuleErrors: readonly string[] = [
  'Failed to fetch dynamically imported module',
  'Failed to load module script',
];

const RouteErrorBoundary = () => {
  const {_} = useLingui();
  const navigate = useNavigate();
  const error = useRouteError() as IError;
  console.warn('[RouteErrorBoundary] ', error);

  if (error.message && importModuleErrors.some(err => error.message.includes(err))) {
    return (
      <Layout
        style={{
          textAlign: 'center',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Result
          icon={<SmileOutlined />}
          title={_(msg`Hay una nueva versión disponible`)}
          subTitle={_(
            msg`Es necesario recargar la página. Si este mensaje persiste, revisa tu conexión o reporta al administrador.`,
          )}
          extra={
            <Button type="primary" onClick={() => window.location.reload()}>
              <ReloadOutlined /> <Trans>Recargar</Trans>
            </Button>
          }
        />
      </Layout>
    );
  }

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return (
        <Layout style={{minHeight: '100vh'}}>
          <Result
            status="404"
            title={_(msg`Página no encontrada`)}
            subTitle={_(msg`La página no existe o ha sido movida`)}
            extra={
              <Fragment>
                <Button type="primary" onClick={() => navigate(-1)}>
                  <ArrowLeftOutlined /> <Trans>Volver atrás</Trans>
                </Button>
                <Button type="primary" onClick={() => navigate('/')}>
                  <HomeOutlined /> <Trans>Ir al inicio</Trans>
                </Button>
              </Fragment>
            }
          />
        </Layout>
      );
    }
  }

  return (
    <Layout style={{minHeight: '100vh'}}>
      <Result
        status="500"
        title={_(msg`Ha ocurrido un error`)}
        subTitle={_(msg`Por favor, intenta de nuevo. Si el problema persiste contacta con el administrador.`)}
        extra={
          <Button type="primary" onClick={() => window.location.reload()}>
            <ReloadOutlined /> <Trans>Recargar</Trans>
          </Button>
        }
      />
    </Layout>
  );
};

export {RouteErrorBoundary};
