import {ApolloProvider, useReactiveVar} from '@apollo/client';
import {RouterRoot} from '@app/Router';
import {isIframe} from '@app/services/store';
import {loadLangCatalog} from '@app/utils/i18n';
import {antdConfig, compactMode, themeMode} from '@app/utils/theme';
import {i18n} from '@lingui/core';
import {I18nProvider} from '@lingui/react';
import * as Sentry from '@sentry/react';
import {App as AntdApp, ConfigProvider} from 'antd';
import esES from 'antd/locale/es_ES';
import React, {Suspense, useEffect} from 'react';
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import client from './graphql';
import './index.css';
import {LoadingFullscreen} from './ui';

void loadLangCatalog();

if (import.meta.env.PROD)
  Sentry.init({
    dsn: 'https://fbe4a26fe53a566533f51b767edda6ea@o4506942634852352.ingest.us.sentry.io/4506944780632064',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

function Root() {
  const mode = useReactiveVar(themeMode);
  const compact = useReactiveVar(compactMode);

  useEffect(() => {
    localStorage.setItem('themeMode', mode);
    localStorage.setItem('compactMode', compact);
    isIframe(window !== window.parent);
  }, [compact, mode]);

  return (
    <React.StrictMode>
      <I18nProvider i18n={i18n}>
        <ApolloProvider client={client}>
          <ConfigProvider theme={antdConfig()} locale={esES}>
            <AntdApp>
              <Suspense fallback={<LoadingFullscreen />}>
                <RouterProvider router={createBrowserRouter(RouterRoot())} />
              </Suspense>
            </AntdApp>
          </ConfigProvider>
        </ApolloProvider>
      </I18nProvider>
    </React.StrictMode>
  );
}

export default Root;
