import {Component} from 'react';
import './Logo.css';

class Logo extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" data-name="Capa 1" viewBox="0 0 294.78 294.78">
        <defs>
          <linearGradient id="a" x1={167.89} x2={284.86} y1={264.28} y2={264.28} gradientUnits="userSpaceOnUse">
            <stop offset={0} stopColor="#4cc1f0" />
            <stop offset={0.5} stopColor="#1a9fdb" />
            <stop offset={1} stopColor="#118acb" />
          </linearGradient>
          <linearGradient id="b" x1={213.05} x2={356.67} y1={200.96} y2={200.96} gradientUnits="userSpaceOnUse">
            <stop offset={0} stopColor="#5f509d" />
            <stop offset={0.5} stopColor="#2d2d7e" />
            <stop offset={1} stopColor="#46396a" />
          </linearGradient>
          <linearGradient id="c" x1={239.76} x2={353.97} y1={258.07} y2={258.07} gradientUnits="userSpaceOnUse">
            <stop offset={0} stopColor="#d86fa9" />
            <stop offset={0.5} stopColor="#c70a7f" />
            <stop offset={1} stopColor="#a11e81" />
          </linearGradient>
          <linearGradient id="d" x1={213.05} x2={311.57} y1={281.35} y2={281.35} gradientUnits="userSpaceOnUse">
            <stop offset={0} stopColor="#ffecaa" />
            <stop offset={0.5} stopColor="#fecb00" />
            <stop offset={1} stopColor="#f8ae00" />
          </linearGradient>
        </defs>
        <g data-name="Dark">
          <path
            d="M259.4 107.9h147.41v147.36c0 81.06-66.35 147.42-147.41 147.42H112V255.26c0-81.06 66.3-147.36 147.36-147.36Z"
            style={{
              fill: '#1a1a1a',
              fillRule: 'evenodd',
            }}
            transform="translate(-112.04 -107.9)"
          />
          <path
            d="M213.05 235.52v107.76a44.27 44.27 0 0 1-44.17 44.17h-1V235.52a94.32 94.32 0 0 1 117-91.66 94.32 94.32 0 0 0-71.81 91.66Z"
            style={{
              fillRule: 'evenodd',
              fill: 'url(#a)',
            }}
            transform="translate(-112.04 -107.9)"
          />
          <path
            d="M262.31 186.26A94.46 94.46 0 0 1 354 258.07a94.55 94.55 0 0 0-69.11-114.21 94.29 94.29 0 0 0-71.81 91.66 49.23 49.23 0 0 1 49.26-49.26Z"
            style={{
              fillRule: 'evenodd',
              fill: 'url(#b)',
            }}
            transform="translate(-112.04 -107.9)"
          />
          <path
            d="M354 258.07a94.29 94.29 0 0 0-91.66-71.81 49.27 49.27 0 0 1 49.26 49.26 94.4 94.4 0 0 1-71.81 91.66A94.55 94.55 0 0 0 354 258.07Z"
            style={{
              fillRule: 'evenodd',
              fill: 'url(#c)',
            }}
            transform="translate(-112.04 -107.9)"
          />
          <path
            d="M262.31 284.78a49.24 49.24 0 0 1-49.26-49.26v80.54a94.33 94.33 0 0 0 26.71 11.12 94.4 94.4 0 0 0 71.81-91.66 49.27 49.27 0 0 1-49.26 49.26Z"
            style={{
              fillRule: 'evenodd',
              fill: 'url(#d)',
            }}
            transform="translate(-112.04 -107.9)"
          />
        </g>
      </svg>
    );
  }
}

export {Logo};
