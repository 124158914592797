import {ApolloClient, ApolloError, InMemoryCache, from, fromPromise} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {createPersistedQueryLink} from '@apollo/client/link/persisted-queries';
import {getAccessToken, setAccessToken} from '@app/services/store';
import {toCamelCase} from '@app/utils/case';
import {t} from '@lingui/macro';
import {notification} from 'antd';
import {createLink} from 'apollo-absinthe-upload-link';
import {sha256} from 'crypto-hash';
import {ASTNode, print} from 'graphql';
import {RefreshTokenDocument} from './__generated__/graphql';
import {relayStylePagination} from '@apollo/client/utilities';

export const fetchGraphQLErrorCode = ({
  graphQLErrors,
}: ApolloError): {code: string | null; field: string | null; message: string | null} => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      return {
        code: err.extensions.code as string,
        field: err.extensions.inputKey ? toCamelCase(err.extensions.inputKey as string) : null,
        message: err.message,
      };
    }
  }
  return {code: null, field: null, message: null};
};

let isRefreshing = false;
let pendingRequests: (() => void)[] = [];

const resolvePendingRequests = () => {
  pendingRequests.map(callback => callback());
  pendingRequests = [];
};

// const removeError = (forward: NextLink, operation: Operation) => {
//   return forward(operation).map((response) => {
//     if (response.errors) {
//       response.errors = undefined;
//     }
//     return response;
//   });
// };

const handledErrors = ['INVALID_JOIN_CODE', 'AVAILABILITY_OVERLAPS', 'BOOKING_IN_PAST'];

const errorLink = onError(({graphQLErrors, networkError, operation, forward}) => {
  let forward$;

  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      if (err.path && err.path[0] == 'refreshToken') return;
      if (handledErrors.includes(err.extensions?.code as string)) {
        return;
      }

      switch (err.extensions?.code) {
        case 'FLOOD_LIMIT':
          notification.error({
            message: t`No tan rápido`,
            description: t`No se pudo completar la operación. Intenta de nuevo más tarde.`,
          });
          break;

        case 'UNAUTHORIZED_FIELD':
          if (err.path && err.path.includes('joinCodes') && err.path.includes('code')) return;
          if (err.path && err.path[err.path.length - 1] == 'priority') {
            return;
            //return removeError(forward, operation);
          }

          // notification.warning({
          //   message: 'No autorizado',
          //   description: 'Parece que no tienes permiso para acceder a toda la información.',
          // });
          break;

        case 'UNAUTHORIZED':
          if (err.path && err.path[err.path.length - 1] == 'priority') {
            return;
            //return removeError(forward, operation);
          }

          notification.error({
            message: t`No autorizado`,
            description: t`Parece que no tienes permiso para ejecutar esa acción.`,
          });
          break;

        case 'UNAUTHENTICATED':
          if (err.path && err.path[0] == 'signOut') return;
          console.log('[errorLink] UNAUTHENTICATED');
          if (!isRefreshing) {
            isRefreshing = true;
            forward$ = fromPromise(
              client
                .mutate({mutation: RefreshTokenDocument})
                .then(({data}) => {
                  const accessToken = data?.refreshToken?.access;
                  if (accessToken) {
                    setAccessToken(accessToken);
                    return true;
                  } else {
                    return false;
                  }
                })
                .then(() => {
                  resolvePendingRequests();
                  return true;
                })
                .catch(() => {
                  pendingRequests = [];
                  return false;
                })
                .finally(() => {
                  isRefreshing = false;
                  return false;
                }),
            );
          } else {
            forward$ = fromPromise(
              new Promise<void>(resolve => {
                pendingRequests.push(() => resolve());
              }),
            );
          }

          return forward$.flatMap(() => forward(operation));
        default:
          console.log(
            `[GraphQL Error]: Code: ${err.extensions?.code?.toString()}, Message: ${err.message}, Path: ${err?.path?.toString()}`,
          );
      }
    }
  }

  if (networkError) console.log('[Network error]: ', networkError);
});

const persistedQueriesLink = createPersistedQueryLink({sha256});

const httpLink = persistedQueriesLink.concat(
  createLink({
    uri: import.meta.env.VITE_API_HTTP_HOST,
    credentials: 'include',
    print: (ast: ASTNode) => print(ast),
  }),
);

const authLink = setContext((_operation, {headers}) => {
  const token = getAccessToken();

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const cache = new InMemoryCache({
  typePolicies: {
    Customer: {
      fields: {
        transactions: relayStylePagination(),
        accounts: relayStylePagination(),
      },
    },
  },
});

const client = new ApolloClient({
  cache: cache,
  link: from([errorLink, authLink.concat(httpLink)]),
});

export default client;
