import {Maybe, User} from '@gql/graphql';
import {getFirstLetter, getUniqueColor} from '@app/utils/names';
import {UserOutlined} from '@ant-design/icons';
import {Avatar as AvatarAntd, AvatarProps} from 'antd';

export interface customProps {
  viewer?: Maybe<User>;
}

type CustomAvatarProps = AvatarProps & customProps;

const avatarConfig = (props: CustomAvatarProps) => {
  if (!props.viewer) return {icon: <UserOutlined />};
  const size = props.size ? props.size : 'large';

  const name = getFirstLetter(props.viewer);
  const defaultConf = {
    size: size,
    children: name,
    style: {
      backgroundColor: getUniqueColor(props.viewer),
      verticalAlign: 'middle',
    },
  };

  return defaultConf;
};

const UserAvatar = (props: CustomAvatarProps) => {
  const config = avatarConfig(props);

  return <AvatarAntd {...config} />;
};
export {UserAvatar};
